import React from "react";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Title from "components/fcdt-title/fcdt-title";
import { IoTrashOutline } from "react-icons/io5";
import style from "./style/recommended-task.module.scss";
import IconAddTask from "assets/icons/icon_add_task.webp";
import IconAddSubTask from "assets/icons/icon_add_subtask.webp";
import Button from "components/buttons/fcdt-button";
import { RecommendedTaskProps } from "./model";
import { Link } from "react-router-dom";

const RecomendedTask = (props: RecommendedTaskProps) => {
  const { handleNavigate, handleClickService, taskRecomended } = props;

  return (
    <div className={style.container_principal}>
      <Title fontSize={20} fontWeight={600}>
        Acciones recomendadas
      </Title>
      <Paragraph className={style.paragrap_gray}>
        A continuación, te presentamos las acciones recomendadas sobre el acta
        que vas a revisar
      </Paragraph>
      <section className={style.section_titles}>
        <div className={style.container_action_selection}>
          <Title fontSize={18}>Acciones</Title>
          <Paragraph fontSize={14} className={style.paragrap_gray}>
            Selecciona si es tarea o subtarea
          </Paragraph>
        </div>
        <div
          className={`${style.container_icons} ${style.container_add_task_text}`}
        >
          <span className={style.span_title_icon}>Eliminar</span>
          <span className={style.span_title_icon}>Añadir tarea</span>
          <span className={style.span_title_icon}>Añadir subtarea</span>
        </div>
      </section>

      <section className={style.section_box}>
        {taskRecomended.map((item) => {
          return (
            <div className={style.container_content} key={item.id}>
              <div className={style.container_action_selection}>
                <li>{item.task_name}</li>
              </div>
              <div className={style.container_icons}>
                <IoTrashOutline
                  size={30}
                  className={`${style.paragrap_gray} ${style.icon}`}
                  onClick={() => handleClickService(1, item.id)}
                />
                <img
                  src={IconAddTask}
                  alt=""
                  className={style.icon}
                  onClick={() => handleClickService(2, item.id)}
                />
                <img
                  src={IconAddSubTask}
                  alt=""
                  className={style.icon}
                  onClick={() => handleClickService(3, item.id)}
                />
              </div>
            </div>
          );
        })}
      </section>

      <section className={style.section_buttons}>
        <Button
          className="fcdt_btn_without_background_primary"
          type="button"
          onClick={() => handleNavigate(1)}
        >
          Atrás
        </Button>

        <Link className={`fcdt-link-to_white ${style.link}`} to={"editar"}>
          <Button type="submit">Siguiente</Button>
        </Link>
      </section>
    </div>
  );
};

export default RecomendedTask;
