import React, { useEffect } from "react";
import { Notification } from "./notification";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { getNotifications } from "pages/collaborator/store/slices/notification";
import ModalDatesContainer from "./components/modal-dates/modal-dates-container";

export const NotificationContainer = () => {
  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });

  const { notifications, isReloadNeed, isFeching } = useSelector(
    (state: RootState) => {
      return state.colaborator.notifications;
    }
  );

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { toggleChecked } = useSelector((state: RootState) => {
    return state.dashboard;
  });

  const dispatch = useDispatch<any>();
  const value = filterParams?.search;

  const normalizeAndCompare = (
    text: string | undefined,
    searchTerm: string
  ): boolean => {
    const normalizedText = text
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const normalizedSearchTerm = searchTerm
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return (
      normalizedText?.includes(normalizedSearchTerm) ||
      normalizedText === normalizedSearchTerm
    );
  };
  const datanotifications = toggleChecked
    ? notifications?.no_revisadas
    : notifications?.revisadas;

  const filtered = datanotifications?.filter((item) => {
    const searchTerm = value ? value : "";
    const nameMatch = normalizeAndCompare(
      item?.assigner_info.name_in_meeting_platform,
      searchTerm
    );
    const emailMatch = normalizeAndCompare(
      item.assigner_info.email,
      searchTerm
    );

    return nameMatch || emailMatch;
  });

  useEffect(() => {
    if (isReloadNeed && !isFeching && dataUser) {
      dispatch(getNotifications());
    }
  }, [isReloadNeed, isFeching, dataUser, dispatch]);

  return (
    <>
      <Notification notifications={filtered} toggleChecked={toggleChecked} />
      <ModalDatesContainer />
    </>
  );
};
