export const NUMBER_ITEMS_PAGINATION = 3;

export const REG_EXP = {
  FULL_NAME: "^[a-zA-ZáéíóúñÁÉÍÓÚ\\s]{6,50}$",
  EMAIL: "^[a-zA-Z0-9._\\-]+@[a-zA-Z0-9._\\-]+\\.[a-zA-Z]{2,}$",
  FULL_TEXT_MESSAGE: "^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ.,\-_/;:]*$",
  PASSWORD: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@#$%*.\\-])[A-Za-z\\d@#$%*.\\-]{8,}$",
  COMPANY_NAME: "^[^\^\{\}\[\]&áéíóúÁÉÍÓÚüÜñÑ´¨0-9a-zA-Z\s]{3,150}$",
  PROCESS_NAME: "^[^\^\{\}\[\]&áéíóúÁÉÍÓÚüÜñÑ´¨0-9a-zA-Z\s]{3,250}$",
  PROCESS_DESCRIPTIONS: "^[^\^\{\}\[\]&áéíóúÁÉÍÓÚüÜñÑ´¨0-9a-zA-Z\s]{3,500}$",
};

export const TITLE_REG_EXP = {
  FULL_NAME: "Usar únicamente letras con un mínimo 6 y máximo de 50 caracteres",
  EMAIL: "Digite una dirección de correo válida. Ej: email@ejemplo.com",
  FULL_TEXT_MESSAGE:
    "Se puede usar número y letras, tildes, signos de puntuación básicos como la coma (,), el punto (.), el guion (-), el guion bajo (_), la barra (/), el punto y coma (;), el dos puntos (:).",
  PASSWORD:
    "La contraseña debe tener entre 8 y 50 caracteres, debe contener minúscula(s), mayúscula(s), número(s) y al menos un caracter especial (@+.*-_/%#$,).",
  COMPANY_NAME:
    "Usar únicamente letras con tildes, puntos, guiones, mayúsculas y minúsculas",
  PROCESS_NAME:
    "Usar únicamente letras con tildes, puntos, guiones, mayúsculas y minúsculas",
};
