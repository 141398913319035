import { DataMultiSelect } from "components/fcdt-multiselect/model";

export const dataEventKnowledge: DataMultiSelect[] = [
  { uid: "-1", label: "Seleccionar todos" },
  { uid: "1", label: "Reuniones" },
  { uid: "2", label: "Tareas" },
  { uid: "3", label: "Informes" },
];

export const dataEventFollowUp: DataMultiSelect[] = [
  { uid: "1", label: "Reuniones" },
  { uid: "2", label: "Tareas" },
];
