import { useEffect, useState } from "react";
import { NewPassword } from "components/modals/components/new-password";
import Dialog from "components/modals/dialog";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { verifyTokenRestorePassword } from "store/slices/auth";
import { setOpenNewPassword } from "store/slices/login";
import { RootState } from "store/store";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";

export const RestorePassword = () => {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const token = params.token!;

  const [isVerified, setIsverified] = useState(0);

  const { isOpenNewPassword } = useSelector((state: RootState) => {
    return state.login;
  });

  const handleCloseDialog = () => {
    toast.error("Debes ingresar tu nueva contraseña", {duration: 7000, position:"top-center"});
  };

  useEffect(() => {
    const verificationToken = async () => {
      const res: any = await dispatch(verifyTokenRestorePassword(token));
      console.log({ res });
      if (!res?.ok) {
        toast.error(res?.msg, {duration: 7000, position:"top-center"});
        setIsverified(2);
        return;
      }
      dispatch(setOpenNewPassword(true));
      setIsverified(1);
    };
    if (token) {
      verificationToken();
    }
  }, [token]);

  return (
    <div style={{ padding: "10px" }}>
      <Paragraph>{isVerified === 2 && "No autorizado"}</Paragraph>
      {isVerified === 1 && (
        <Dialog isOpen={isOpenNewPassword} onClose={handleCloseDialog}>
          <NewPassword />
        </Dialog>
      )}
    </div>
  );
};
