import FCDTModal from "components/modal-notification/fcdt-modal";
import style from "./style/modal-show-meeting.module.scss";
import { BiSolidRectangle } from "react-icons/bi";
import {
  IoCalendarNumberOutline,
  IoCreateOutline,
  IoNotificationsOutline,
} from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Title from "components/fcdt-title/fcdt-title";
import Button from "components/buttons/fcdt-button";
import { ShowModalMeetingProps } from "./model";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useState } from "react";

const ModalShowMeeting = (props: ShowModalMeetingProps) => {
  const {
    openModal,
    handleCloseModal,
    dataMeeting,
    handleNavigate,
    handleRejectMeeting,
    isLeader,
  } = props;
  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });
  let datestartevent =String(dataMeeting.meeting.meeting_start_date).replace(":00.000Z", "").replace("T", " hora ");
  let datefinalevet = String(dataMeeting.meeting.meeting_end_date).replace(":00.000Z", "").replace("T", " hora ");

  
  const [dataMeetingSelect, setDataMeetingSelect] = useState(dataMeeting?.meeting.created_by===dataUser?.collaborator.uid);
  const children = (
    <section className={style.container_modal}>
      <div className={style.container_icons} hidden={!dataMeetingSelect}>
        <IoCreateOutline size={27} onClick={handleNavigate} />
      </div>

      <div className={style.container_title_date}>
        <p>
          <BiSolidRectangle size={30} color="#00de97" />
          <Paragraph fontSize={20}>
            {dataMeeting.meeting.meeting_name}
          </Paragraph>
        </p>
        <p className={style.paragrap_date}>
          <span>
            {datestartevent +
              " Hasta el " +
              datefinalevet}
          </span>
        </p>
      </div>
      <div className={style.container_description}>
        <p>
          <IoNotificationsOutline size={25} />
          <span>{"Menos de 30 minutos antes"}</span>
        </p>
        <p>
          <IoCalendarNumberOutline size={25} />
          <span>{dataMeeting.created_by}</span>
        </p>
        <div className={style.container_person}>
          <p>
            <CiUser size={25} />
            <span>Participantes invitados:</span>
          </p>
          <ul>
            {dataMeeting.collaborators.map((item) => {
              return <li key={item.uid}>{item.name}</li>;
            })}
          </ul>
        </div>

        <Title
          fontSize={16}
          fontWeight={500}
          className={style.title_description}
        >
          Descripción del evento:
        </Title>
        <Paragraph className={style.Paragraph_description}>
          {dataMeeting.meeting.meeting_description}
        </Paragraph>
      </div>

      <div className={style.container_link_meeting}>
        <a href={dataMeeting.meeting.meeting_link} target="_blank">
          Ingresar a la reunión
        </a>
      </div>

      <div className={style.container_process_goal}>
        <section>
          <Title fontSize={16}>Proceso</Title>
          <span>{dataMeeting.process_name}</span>
        </section>
        <section>
          <div>
            <Title fontSize={16}>Meta</Title>
          </div>
          <span>{dataMeeting.goal_title}</span>
        </section>
      </div>

      <section className={style.section_buttons}>
        <Button
          className="fcdt_btn_without_background_primary"
          type="button"
          onClick={handleRejectMeeting}
          disabled={!dataMeetingSelect}
        >
          Cancelar reunión
        </Button>
        <Button type="button" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </section>
    </section>
  );

  return (
    <FCDTModal
      open={openModal}
      children={children}
      handleClose={handleCloseModal}
    />
  );
};

export default ModalShowMeeting;
