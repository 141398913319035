import { GoPlusCircle } from "react-icons/go";
import style from "../style/newprocess.module.scss";
import Button from "components/buttons/fcdt-button";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { IoMdClose } from "react-icons/io";
import {
  createProcess,
  getLeaders,
  getProcess,
  setFormProcess,
  setLeaders,
  setOpenNewGoal,
  setOpenNewLeader,
  setSelectedProcess,
  setUpdateProcess,
  setisOpenNewProcess,
  updateProcess,
} from "pages/admin/store/slices/process";
import { ProcessData } from "pages/admin/components/process/models";
import toast from "react-hot-toast";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";

export const NewProcess = () => {
  const { register, handleSubmit, watch, reset, setValue } =
    useForm<ProcessData>();
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState(0);
  const {
    isUpdateProcess,
    selectedProcess,
    leaders,
    isOpenNewGoal,
    isOpenNewLeader,
    isOpenNewProcess,
    formProcess,
  } = useSelector((state: RootState) => {
    return state.admin.process;
  });

  const normalizeAndCompare = (
    text: string | undefined,
    searchTerm: string
  ): boolean => {
    const normalizedText = text
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const normalizedSearchTerm = searchTerm
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return (
      normalizedText?.includes(normalizedSearchTerm) ||
      normalizedText === normalizedSearchTerm
    );
  };

  const filteredLeaders = leaders?.filter((item: any) => {
    const searchTerm = watch()?.process_leader_name
      ? watch()?.process_leader_name
      : "";
    const nameMatch = normalizeAndCompare(
      item?.name_in_meeting_platform,
      searchTerm
    );
    return nameMatch;
  });
  const listLeaders = filteredLeaders;

  const handleOpenDialogLeader = () => {
    dispatch(setOpenNewLeader(!isOpenNewLeader));
    dispatch(setisOpenNewProcess(!isOpenNewProcess));
  };
  const handleOpenDialogGoal = () => {
    dispatch(setFormProcess(watch()));
    dispatch(setOpenNewGoal(!isOpenNewGoal));
    dispatch(setisOpenNewProcess(!isOpenNewProcess));
  };
  const handleOpenDialogProcess = () => {
    dispatch(setisOpenNewProcess(!isOpenNewProcess));
    dispatch(setUpdateProcess(false));
    dispatch(setSelectedProcess(undefined));
  };
  const onSubmit = async (data: ProcessData) => {
    const response: any = isUpdateProcess
      ? await dispatch(updateProcess(data))
      : await dispatch(createProcess(data));
    if (response?.ok) {
      toast.success(response.msg, {duration: 7000, position:"top-center"});
      dispatch(setisOpenNewProcess(!isOpenNewProcess));
      dispatch(setOpenNewGoal(!isOpenNewGoal));
      // dispatch(setProcessId(response?.processId));
      dispatch(getProcess());
    }
    if (!response?.ok) {
      toast.error(response.msg, {duration: 7000, position:"top-center"});      
      dispatch(setisOpenNewProcess(!isOpenNewProcess));
      // dispatch(setOpenNewGoal(!isOpenNewGoal));
      // dispatch(setProcessId(response?.processId));
      dispatch(getProcess());
    }
  };
  useEffect(() => {
    const fetchLeaders = async () => {
      const response = await dispatch(getLeaders());
      if (response?.ok) {
        dispatch(setLeaders(response.users));
      }
    };
    fetchLeaders();
    if (isUpdateProcess) {
      reset({
        process_name: selectedProcess?.proceso?.process_name,
        descriptive_paragraph: selectedProcess?.proceso?.descriptive_paragraph,
        process_leader: selectedProcess?.collaborator?.uid,
        process_leader_name: selectedProcess?.collaborator?.id_person?.name,
      });
    }
    if (formProcess && isOpenNewProcess) {
      reset(formProcess);
      setSearch(1);
    }
  }, [formProcess, isOpenNewProcess, isUpdateProcess]);

  return (
    <form className={style.goal_container} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.header}>
        <h2> {isUpdateProcess ? "Actualizar" : "Nuevo"} Proceso</h2>
        <button type="button" onClick={handleOpenDialogProcess}>
          {" "}
          <IoMdClose size={35} color="#2f0084" />{" "}
        </button>
      </div>
      <div className={style.content}>
        <div className={style.content_left}>
          <input
            type="text"
            placeholder="Nombre de proceso"
            required
            minLength={6}
            maxLength={250}
            pattern={REG_EXP.COMPANY_NAME}
            title={TITLE_REG_EXP.COMPANY_NAME}
            {...register("process_name")}
          />
          <textarea
            placeholder="Descripción"
            required
            minLength={6}
            maxLength={500}
            {...register("descriptive_paragraph")}
          />
        </div>
        <div className={style.content_right}>
          {isUpdateProcess ? (
            <button
              type="button"
              className={style.btn_add}
              onClick={handleOpenDialogGoal}
            >
              <GoPlusCircle size={35} color="#00de97" />
              Añadir meta
            </button>
          ) : null}

          <input hidden {...register("process_leader")} required />
          <input
            placeholder="Lider de proceso"
            autoComplete="off"
            onInput={() => setSearch(0)}
            required
            {...register("process_leader_name")}
          />
          {watch().process_leader_name !== "" && search === 0 && (
            <ul className={style.content_search}>
              {listLeaders?.length === 0 ? (
                <li>No hay coincidencias con tu búsqueda</li>
              ) : (
                listLeaders?.map((item: any) => (
                  <li
                    key={item?.uid}
                    onClick={() => {
                      setValue("process_leader_name", item?.id_person.name);
                      setValue("process_leader", item?.uid);
                      setSearch(item?.uid);
                    }}
                  >
                    {item?.id_person.name}
                  </li>
                ))
              )}
              <li>
                <button
                  type="button"
                  className={style.btn_add}
                  onClick={handleOpenDialogLeader}
                >
                  <GoPlusCircle size={35} color="#00de97" />
                  Registrar Lider
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className={style.content_buttons}>
        <Button
          className="btn_cancel"
          children="Cancelar"
          width="40"
          onClick={handleOpenDialogProcess}
        />
        <Button
          children={isUpdateProcess ? "Ver metas asociadas" : "Guardar"}
          type="submit"
          width="40"
        />
      </div>
    </form>
  );
};
