import Title from "components/fcdt-title/fcdt-title";
import style from "../styles/body-follow-up.module.scss";
import FunnelChart from "../../custom-charts/funnel/funnel-chart";
import PieChartBar from "../../custom-charts/pie-bar/pie-bar";
import { Data } from "react-minimal-pie-chart/types/commonTypes";
import GaugeChartSpeed from "../../custom-charts/gauge-chart/gauge-chart";
import BarPercentageChart from "../../custom-charts/bar-percentage/bar-percentage";
import TitleCharts from "../title-chars/title-charts";
import CustomBarChart, {
  DataItemBarChart,
} from "../../custom-charts/bar-chart/cutom-bar-chart";
import GanttChart from "pages/document-management/components/reports/components/gantt-chart/gantt-charts";
import { formatGanttData } from "helpers/formatGanttData";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import ButtonFloatContainer from "../../button_float/button-float-container";

const ProcessCharts = () => {
  const { dataDashboard, processSelected, dataDashboardRecomendation } =
    useSelector((state: RootState) => {
      return state.followup;
    });

  if (!dataDashboard || !processSelected) return null;

  const rows = dataDashboard.data_goals_gantt_chart
    ? formatGanttData(
        dataDashboard.data_goals_gantt_chart.map((item) => {
          return {
            task_name: item.goal_title,
            task_start_date: item.start_date,
            task_due_date: item.expiration_date,
          };
        })
      )
    : [];

  const dataPieGoal: Data = dataDashboard.data_goals_priorities_chart
    ? dataDashboard.data_goals_priorities_chart.map((item) => {
        return {
          title: item.label,
          value: item.value * 100,
          color: item.color,
        };
      })
    : [];

  const dataPieTask: Data = dataDashboard.data_tasks_priorities_chart
    ? dataDashboard.data_tasks_priorities_chart.map((item) => {
        return {
          title: item.label,
          value: item.value * 100,
          color: item.color,
        };
      })
    : [];

  const dataPieSentiments: Data = dataDashboard.data_persons_sentiment_chart
    ? dataDashboard.data_persons_sentiment_chart.map((item) => {
        return {
          title: item.label,
          value: item.value * 100,
          color: item.color,
        };
      })
    : [];

  const dataBarChart: DataItemBarChart[] =
    dataDashboard.data_tasks_mean_completion_times_chart
      ? dataDashboard.data_tasks_mean_completion_times_chart.map((item) => {
          return {
            name: item.label,
            value: item.value,
            fill: item.color,
          };
        })
      : [];

  const dataTunnelGoal = dataDashboard.data_goals_statuses_chart
    ? dataDashboard.data_goals_statuses_chart
    : [];

  const dataFunnelTask = dataDashboard.data_tasks_execution_statuses_chart
    ? dataDashboard.data_tasks_execution_statuses_chart
    : [];

  return (
    <div className={style.container_process_charts}>
      <TitleCharts title="Procesos" subtitle={processSelected.label} />
      <section className={style.section_process}>
        <Title fontSize={18} fontWeight={500}>
          Metas
        </Title>

        <div
          className={`${style.box_graph_shadown} ${style.box_graph_shadown_100}`}
        >
          {dataDashboardRecomendation?.goals_gantt_chart_recommendation && (
            <ButtonFloatContainer
              data={dataDashboardRecomendation.goals_gantt_chart_recommendation}
            />
          )}

          <Title fontSize={17} fontWeight={700} className={style.title_box}>
            Distribución de metas
          </Title>
          {rows.length > 0 ? (
            <GanttChart rows={rows} />
          ) : (
            <Paragraph width="100">No hay datos</Paragraph>
          )}
        </div>

        <div className={style.container_two_chats}>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.goals_statuses_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.goals_statuses_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Estados de ejecución de metas
            </Title>
            {dataTunnelGoal.length > 0 ? (
              <FunnelChart data={dataTunnelGoal} />
            ) : (
              <Paragraph width="100">No hay datos</Paragraph>
            )}
          </div>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.goals_priorities_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.goals_priorities_chart_recommendation
                }
              />
            )}
            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Prioridad de metas
            </Title>
            {dataPieGoal.length > 0 ? (
              <PieChartBar data={dataPieGoal} legend="Prioridad metas" />
            ) : (
              <Paragraph width="100">No hay datos</Paragraph>
            )}
          </div>
        </div>
      </section>
      <section className={style.section_process}>
        <Title fontSize={18} fontWeight={500}>
          Reuniones
        </Title>
        <div className={style.container_two_chats}>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.meetings_wpm_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.meetings_wpm_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Palabras por minuto promedio
            </Title>
            {dataDashboard.data_meetings_wpm_chart ? (
              <GaugeChartSpeed
                speed={dataDashboard.data_meetings_wpm_chart.wpm}
                percentage={dataDashboard.data_meetings_wpm_chart.percent}
              />
            ) : (
              <Paragraph width="100">No hay datos</Paragraph>
            )}
          </div>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.meetings_atetndance_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.meetings_atetndance_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              % de asistencia promedio
            </Title>
            {dataDashboard.data_meetings_attendance_chart ? (
              <BarPercentageChart
                percetage={dataDashboard.data_meetings_attendance_chart.percent}
              />
            ) : (
              <Paragraph>No hay datos</Paragraph>
            )}
          </div>
        </div>
      </section>
      <section className={style.section_process}>
        <Title fontSize={18} fontWeight={500}>
          Tareas
        </Title>
        <div className={style.container_two_chats}>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.tasks_execution_statuses_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.tasks_execution_statuses_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Estados de ejecución de tareas
            </Title>
            {dataFunnelTask.length > 0 ? (
              <FunnelChart data={dataFunnelTask} />
            ) : (
              <Paragraph width="100">No hay datos</Paragraph>
            )}
          </div>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.tasks_priorities_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.tasks_priorities_chart_recommendation
                }
              />
            )}
            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Prioridad
            </Title>
            {dataPieTask.length > 0 ? (
              <PieChartBar data={dataPieTask} legend="Prioridad de tareas" />
            ) : (
              <Paragraph width="100">No hay datos</Paragraph>
            )}
          </div>
        </div>
      </section>
      <section className={style.section_process}>
        <Title fontSize={18} fontWeight={500}>
          Personas
        </Title>
        <div className={style.container_two_chats}>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.persons_sentiment_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.persons_sentiment_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Estados emocionales intervenciones
            </Title>
            {dataDashboard.data_persons_sentiment_chart ? (
              <PieChartBar
                data={dataPieSentiments}
                itemLegentRectangular={true}
              />
            ) : (
              <Paragraph>No hay datos</Paragraph>
            )}
          </div>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.tasks_mean_completion_times_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.tasks_mean_completion_times_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Tiempos promedio de entregas
            </Title>
            <CustomBarChart data={dataBarChart} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProcessCharts;
