import { ComponentsLandingProps } from "pages/landing-page/model";
import React from "react";
import style from "../../style/landing-page.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import CH1_4 from "assets/ch1_4.webp";
import CH2 from "assets/ch2.webp";
import CH3 from "assets/ch3.webp";
import CH5 from "assets/ch5.webp";
import CH6 from "assets/ch6.webp";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";

const Characteristics = (props: ComponentsLandingProps) => {
  const data: { title: string; description: string; url: string }[] = [
    {
      title: "Generación de informes",
      description:
        "Con la automatización de la inteligencia artificial Rykwa genera los informes y actas para tu empresa.",
      url: CH1_4,
    },
    {
      title: "Monitoreo de actividades",
      description:
        "Rykwa ofrece servicios clave como documentación automática, monitoreo de personas y sus tareas asignadas",
      url: CH2,
    },
    {
      title: "Ahorra tiempo",
      description:
        "Utiliza Rykwa para agilizar tus reuniones y hacerlas más productivas.",
      url: CH3,
    },
    {
      title: "Herramientas comunicativas",
      description:
        "Administra tus reuniones de manera dinámica y efectiva con las herramientas proporcionadas por Rykwa",
      url: CH1_4,
    },
    {
      title: "Automatización inteligente",
      description:
        "Olvídate de la digitación manual. Rykwa emplea IA para generar la documentación necesaria de forma automática.",
      url: CH5,
    },
    {
      title: "Gestión dinámica",
      description:
        "Administra tus reuniones de manera dinámica y efectiva con las herramientas proporcionadas por Rykwa.",
      url: CH6,
    },
  ];

  return (
    <div id={props.id} className={style.container_characteristics}>
      <Title className={style.title}>
        Trabajamos para mejorar la documentación de tu empresa
      </Title>

      <div className={style.container_cards_characteristics}>
        {data.map((item, index) => {
          return (
            <section key={index}>
              <img src={item.url} alt="logo characteristics" />
              <div className={style.card}>
                <Paragraph className={style.container_paragraph} fontSize={20}>
                  <strong>{item.title}</strong>
                  <p>{item.description}</p>
                </Paragraph>
              </div>
            </section>
          );
        })}
      </div>
    </div>
  );
};

export default Characteristics;
