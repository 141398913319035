import style from "./styles/calendar.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import {
  IoAddCircleOutline,
  IoChevronBackOutline,
  IoChevronForwardOutline,
} from "react-icons/io5";
import { daysOfWeek, months } from "./contants";
import { CustomCalendarProps } from "./model";

const CustomCalendar = (props: CustomCalendarProps) => {
  const {
    handleNavigate,
    currentDate,
    selectedDates,
    handlePrevMonth,
    handleNextMonth,
    handleDateClick,
    isLeader,
  } = props;

  const endOfMonth = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth() + 1,
    0
  );

  const startOfMonth = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    1
  );

  const daysInMonthRef = endOfMonth.getUTCDate();
  const startDayRef = startOfMonth.getUTCDay();

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < startDayRef; i++) {
      days.push(
        <div
          key={`empty-${i}`}
          className={`${style.calendar_day} ${style.empty}}`}
        ></div>
      );
    }
    for (let day = 1; day <= daysInMonthRef; day++) {
      let date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );

      let formattedDate = `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;

      let isSelected = selectedDates?.includes(formattedDate);

      days.push(
        <div
          key={day}
          className={`${style.calendar_day} ${
            isSelected ? `${style.selected}` : ""
          }`}
          onClick={() => handleDateClick(day)}
        >
          {day}
        </div>
      );
    }
    return days;
  };

  return (
    <div className={style.container_principal}>
      <button
        onClick={handleNavigate}
        className={style.button_create}
        disabled={!isLeader}
      >
        <Title fontSize={20} className={style.title}>
          <IoAddCircleOutline className={style.icon} size={25} />
          Crear evento
        </Title>
      </button>

      <br />

      <div className={style.calendar_container}>
        <div className={style.calendar_header}>
          <button onClick={handlePrevMonth}>
            <IoChevronBackOutline size={30} className={style.icon_header} />
          </button>
          <div>
            {months[currentDate.getMonth()]} {currentDate.getFullYear()}
          </div>
          <button onClick={handleNextMonth}>
            {" "}
            <IoChevronForwardOutline size={30} className={style.icon_header} />
          </button>
        </div>
        <div className={style.calendar_grid}>
          {daysOfWeek.map((day, index) => (
            <div key={index} className={style.calendar_day_header}>
              {day}
            </div>
          ))}
          {renderDays()}
        </div>
      </div>
    </div>
  );
};

export default CustomCalendar;
