import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import * as API from "../../pages/login/api/api";
import { displayLoader, removeProcess } from "./loading";
import { DataUser, DataUserSuperAdmin } from "common/models";
import toast from "react-hot-toast";

type InitialState = {
  isVerifyToken?: boolean;
  dataUser?: DataUser;
  dataSuperAdmin?: DataUserSuperAdmin;
  currentPath: string;
  currentDate: string;
};
const initialState: InitialState = {
  isVerifyToken: false,
  dataUser: undefined,
  currentPath: "",
  currentDate: "",
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setVerifyToken: (state, action) => {
      state.isVerifyToken = action.payload;
    },
    setDataUser: (state, action) => {
      state.dataUser = action.payload;
    },
    setDataSuperAdmin: (state, action) => {
      state.dataSuperAdmin = action.payload;
    },
    setCurrentPath: (state, action: PayloadAction<string>) => {
      const actualDate = new Date();
      state.currentPath = action.payload;
      state.currentDate = actualDate.toISOString().split("T")[0];
    },
  },
});
export const {
  setVerifyToken,
  setDataUser,
  setDataSuperAdmin,
  setCurrentPath,
} = slice.actions;

export default slice.reducer;

export const verifyToken =
  (token: string): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response: any = await API.verifyToken(token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };
export const verifyTokenRestorePassword =
  (token: string): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response: any = await API.verifyTokenRestorePassword(token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
    }
  };

export const checkTokenExpiration = (): AppThunk => async (dispatch) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const token = localStorage.getItem("token");
    if (!token) return;
    const response: any = await API.checkTokenExpiration(token);
    if (response.collaborator) {
      dispatch(slice.actions.setDataUser({ ...response, token }));
    } else {
      dispatch(slice.actions.setDataSuperAdmin({ ...response, token }));
    }
    dispatch(removeProcess(idProcess));
  } catch (error: any) {
    console.log({ error });
    dispatch(removeProcess(idProcess));
  }
};
