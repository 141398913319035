import React from "react";
import style from "../../styles/nav-bar.module.scss";
import MultiSelect from "components/fcdt-multiselect/fcdt-multiselect";
import SelectDates from "../select-dates/select-dates";
import KeyWords from "../keywords/keywords";
import { SelectorsProps } from "../../model";

const Selectors = (props: SelectorsProps) => {
  const {
    processes,
    goals,
    collaborators,
    events,
    handleChangeProcess,
    handleChangeGoals,
    handleChangeCollaborator,
    handleChangeEvents,
  } = props;

  return (
    <div className={style.selectors}>
      <MultiSelect
        label={"Procesos"}
        data={processes}
        onChange={handleChangeProcess}
      />
      <MultiSelect label={"Metas"} data={goals} onChange={handleChangeGoals} />
      <MultiSelect
        label={"Colaborador"}
        data={collaborators}
        onChange={handleChangeCollaborator}
      />
      <MultiSelect
        label={"Evento"}
        data={events}
        onChange={handleChangeEvents}
      />

      <SelectDates />
      <KeyWords />
    </div>
  );
};

export default Selectors;
