import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { displayLoader, removeProcess } from "store/slices/loading";
import { AppThunk } from "store/store";
import {
  AllDataInfoColaborator,
  Colaborator,
  ColaboratorWithReviwer,
  GoalsFromProcess,
  ProcesosCollaboratorLeader,
  ResponseGolasColaborator,
  ResponseProcessesColaborator,
} from "pages/collaborator/components/process/model";
import * as ApiCollaborator from "pages/collaborator/components/process/api/api";
import * as Api from "pages/calendar/api/api";
import { CalendarGoal, CalendarProcess } from "pages/calendar/model";
import {
  FormAddEvent,
  FormRequestAddEvent,
} from "pages/calendar/components/create-event/model";
import { formatDate } from "helpers/formatDate";
import { DetailsMeetings } from "pages/calendar/components/calendar/model";
import { formatHour } from "helpers/formatHour";

type InitialState = {
  isReloadNeeded: boolean;
  isFeching: boolean;
  process: CalendarProcess[];
  goalsFromProcess: CalendarGoal[];
  dataformAddEvent?: FormAddEvent;
  dataFormRequestAddEvent?: FormRequestAddEvent;
  dataAllColaborator: ColaboratorWithReviwer[];
  dataColaboratorSelected: ColaboratorWithReviwer[];
  dataUidColaboratorSelected: string[];
  dataExternalColaboratorSelected: string[];
  reviwer: string;
};
const initialState: InitialState = {
  isReloadNeeded: true,
  isFeching: false,
  process: [],
  goalsFromProcess: [],
  dataformAddEvent: undefined,
  dataFormRequestAddEvent: undefined,
  dataAllColaborator: [],
  dataColaboratorSelected: [],
  dataUidColaboratorSelected: [],
  dataExternalColaboratorSelected: [],
  reviwer: "",
};
export const slice = createSlice({
  name: "create-event",
  initialState,
  reducers: {
    setProcessInit: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = true;
    },
    setProcessSucess: (
      state,
      action: PayloadAction<ProcesosCollaboratorLeader[]>
    ) => {
      state.process = action.payload.map((process) => {
        return {
          nameProcess: process.process_name,
          uid: process.uid,
        };
      });
      state.isReloadNeeded = false;
      state.isFeching = false;
    },
    setProcessError: (state) => {
      state.isReloadNeeded = false;
      state.isFeching = false;
    },
    setGoalsSucess: (state, action: PayloadAction<GoalsFromProcess[]>) => {
      state.goalsFromProcess = action.payload.map((goal) => {
        return {
          nameGoal: goal.goal.goal_title,
          uid: goal.goal.id,
        };
      });
      state.isReloadNeeded = false;
      state.isFeching = false;
    },
    setdataformAddEvent: (state, action: PayloadAction<FormAddEvent>) => {
      state.dataformAddEvent = action.payload;

      if (action.payload.date) {
        const date = formatDate(action.payload.date);
        state.dataFormRequestAddEvent = {
          meetingName: action.payload.eventName,
          meetingDescription: action.payload.eventDescription,
          idProcessGoal: action.payload.idGoal,
          meetingStartDate: `${date}T${action.payload.hour_start}`,
          meetingEndDate: `${date}T${action.payload.hour_end}`,
        };
      }
    },
    setAllColaborator: (
      state,
      actions: PayloadAction<AllDataInfoColaborator[]>
    ) => {
      state.dataAllColaborator = actions.payload.map((colaborator) => {
        return {
          label: colaborator.name_in_meeting_platform,
          uid: colaborator.uid,
          isReviwer: false,
        };
      });
    },
    setDataColaboratorSelected: (
      state,
      actions: PayloadAction<(Colaborator & { isReviwer: boolean })[]>
    ) => {
      state.dataColaboratorSelected = actions.payload;
      state.dataUidColaboratorSelected = actions.payload.map(
        (item) => item.uid
      );
    },
    setDataExternalColaboratorSelected: (
      state,
      actions: PayloadAction<string[]>
    ) => {
      state.dataExternalColaboratorSelected = actions.payload;
    },
    setSelectReviwer: (state, actions: PayloadAction<string>) => {
      state.dataColaboratorSelected = state.dataColaboratorSelected.map(
        (item) => {
          if (item.uid === actions.payload) {
            if (!item.isReviwer) {
              state.reviwer = actions.payload;
            } else {
              state.reviwer = "";
            }
            return { ...item, isReviwer: !item.isReviwer };
          }
          return { ...item, isReviwer: false };
        }
      );
    },
    setDeleteColaborator: (
      state,
      actions: PayloadAction<{ value: string; isExternal: boolean }>
    ) => {
      if (!actions.payload.isExternal) {
        state.dataColaboratorSelected = state.dataColaboratorSelected.filter(
          (item) => item.uid !== actions.payload.value
        );
      } else {
        state.dataExternalColaboratorSelected =
          state.dataExternalColaboratorSelected.filter(
            (item) => item !== actions.payload.value
          );
      }
    },
    setAutocompleteInformationToEdit: (
      state,
      action: PayloadAction<DetailsMeetings>
    ) => {
      state.dataformAddEvent = {
        eventName: action.payload.meeting.meeting_name,
        eventDescription: action.payload.meeting.meeting_description,
        idProcess: action.payload.id_process,
        idGoal: action.payload.meeting.id_process_goal,
        date: formatDate(action.payload.meeting.meeting_start_date),
        hour_start: formatHour(action.payload.meeting.meeting_start_date),
        hour_end: formatHour(action.payload.meeting.meeting_end_date),
      };

      state.dataColaboratorSelected = action.payload.collaborators.map(
        (item) => {
          state.dataUidColaboratorSelected = [];
          state.dataUidColaboratorSelected.push(item.uid);
          return {
            label: item.name_in_meeting_platform,
            uid: item.uid,
            isReviwer:
              item.uid === action.payload.meeting.minute_reviewer
                ? true
                : false,
          };
        }
      );

      state.dataExternalColaboratorSelected =
        action.payload.external_collaborators.map((item) => {
          return item.external_collaborator_email;
        });

      state.reviwer = action.payload.meeting.minute_reviewer;
    },

    resetValuesCreateEvent: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = false;
      state.process = [];
      state.goalsFromProcess = [];
      state.dataformAddEvent = undefined;
      state.dataFormRequestAddEvent = undefined;
      state.dataAllColaborator = [];
      state.dataColaboratorSelected = [];
      state.dataUidColaboratorSelected = [];
      state.dataExternalColaboratorSelected = [];
      state.reviwer = "";
    },
  },
});
export const {
  setdataformAddEvent,
  setDataColaboratorSelected,
  setDataExternalColaboratorSelected,
  setDeleteColaborator,
  setSelectReviwer,
  resetValuesCreateEvent,
  setAutocompleteInformationToEdit,
} = slice.actions;

export default slice.reducer;

export const getProcessesCollaborator =
  (): AppThunk => async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      dispatch(slice.actions.setProcessInit());
      const { token } = getState().auth.dataUser!;
      const response: ResponseProcessesColaborator =
        await ApiCollaborator.getProcessesName(token);
      dispatch(
        slice.actions.setProcessSucess(
          response.procesosAsCollaborator.length > 0
            ? response.procesosAsCollaborator
            : response.procesosAsLeader
        )
      );
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      dispatch(slice.actions.setProcessError());
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getGoalsFromProcess =
  (id_process: string): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const response: ResponseGolasColaborator =
        await ApiCollaborator.getColaboratorGolas(id_process, token);
      dispatch(slice.actions.setGoalsSucess(response.goalsFromProcess));
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getColaborators =
  (idGoal: string): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const responseAllColaborator: AllDataInfoColaborator[] =
        await ApiCollaborator.getAllColaborators(idGoal, token);
      dispatch(slice.actions.setAllColaborator(responseAllColaborator));
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const createMeeting = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const { token, collaborator } = getState().auth.dataUser!;
    const { dataDetailsMeeting } = getState().calendar.calendar;
    const {
      dataFormRequestAddEvent,
      reviwer,
      dataUidColaboratorSelected,
      dataExternalColaboratorSelected,
    } = getState().calendar.createEvent;

    const response: AllDataInfoColaborator[] = !dataDetailsMeeting
      ? await Api.createMeeting(
          dataFormRequestAddEvent!,
          dataUidColaboratorSelected,
          dataExternalColaboratorSelected,
          reviwer.length > 0 ? reviwer : collaborator.uid,
          collaborator.id_meeting_platform,
          token
        )
      : await Api.editMeeting(
          dataDetailsMeeting.meeting.uid,
          dataFormRequestAddEvent!,
          dataUidColaboratorSelected,
          dataExternalColaboratorSelected,
          reviwer,
          collaborator.id_meeting_platform,
          token
        );

    dispatch(removeProcess(idProcess));
    return response;
  } catch (error: any) {
    dispatch(removeProcess(idProcess));
    return false;
  }
};
