import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ROUTES } from "./constants";
import HomeContainer from "pages/home/home-container";
import Redirect from "pages/redirect/redirect";
import RegisterContainer from "pages/register/register-container";
import LoginContainer from "pages/login/login-container";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import PaymentContainer from "pages/payment/payment-container";
import { EmailVerification } from "components/email-verificaction/email-verification";
import { RestorePassword } from "components/newPassword/restore-password";
import RegisterCompanyContainer from "pages/register-company/register-company-container";
import AdminRoutes from "pages/admin/routes/admin-routes";
import CollaboratorRoutes from "pages/collaborator/routes/collaborator-routes";
import SuperAdminRoutes from "pages/superAdmin/routes/super-admin-routes";
import DocumentManagementRoutes from "pages/document-management/routes/document-management-routes";
import PlansContainer from "pages/plans/plans-container";
import StatusPayment from "pages/status-payment/status-payment";
import CalendarRoutes from "pages/calendar/routes/calendar.routes";
import LandingPageContainer from "pages/landing-page/landing-page-container";

const GlobalRoutes = () => {
  const { processes } = useSelector((state: RootState) => {
    return state.loading;
  });

  return (
    <div className={processes.length > 0 ? "fcdt-disable-component" : ""}>
      <BrowserRouter>
        <Routes>
          {/* //////////////////COMMON ROUTES////////// */}
          <Route>
            <Route
              path={ROUTES.LANDING_PAGE}
              Component={LandingPageContainer}
            />
            <Route path={ROUTES.HOME} Component={HomeContainer} />
            <Route path={ROUTES.LOGIN} Component={LoginContainer} />
            <Route
              path={ROUTES.REGISTER_COMPANY}
              Component={RegisterCompanyContainer}
            />
            <Route path={ROUTES.REGISTER} Component={RegisterContainer} />
            <Route path={ROUTES.AUTH} Component={EmailVerification} />
            <Route path={ROUTES.PASSWORDRESET} Component={RestorePassword} />
            <Route path={ROUTES.PLANS} Component={PlansContainer} />
            <Route path={ROUTES.PAYMENT} Component={PaymentContainer} />
            <Route path={ROUTES.STATUS_PAYMENT} Component={StatusPayment} />
            <Route path={ROUTES.PASSWORDRESET} Component={RestorePassword} />

            {/* ////////////////////ROUTES SUPER ADMIN////////// */}

            <Route
              path={ROUTES.SUPER_ADMINISTRATOR}
              element={<SuperAdminRoutes />}
            ></Route>

            {/* ////////////ADMINISTRATOR ROUTES//////// */}

            <Route
              path={ROUTES.ADMINISTRATOR}
              element={<AdminRoutes />}
            ></Route>

            {/* ////////////////////ROUTES COLLABORATORS////////// */}

            <Route
              path={ROUTES.COLLABORATOR.PARENT}
              element={<CollaboratorRoutes />}
            ></Route>
          </Route>

          <Route path="*" Component={Redirect} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default GlobalRoutes;
