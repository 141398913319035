import { ToogleProps } from './model'
import style from './style/toggle.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setToggleChecked } from 'store/slices/dashboard';
export const Toggle = (props: ToogleProps) => {
    const dispatch = useDispatch();
    const { title, titleActive } = props.data
    const { toggleChecked } = useSelector((state: RootState) => {
        return state.dashboard;
    });

    const handleChange = (e: any) => {
        dispatch(setToggleChecked(e.target.value));
    };
    return (
        <div className={style.toggle_container}>
            <input
                type="checkbox"
                id="btn"
                checked={toggleChecked}
                onChange={handleChange} />
            <label htmlFor="btn"><span className={`${toggleChecked && style.active}`}>{toggleChecked ? titleActive : title}</span></label>

        </div>
    )
}
