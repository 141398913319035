import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { displayLoader, removeProcess } from "store/slices/loading";
import { AppThunk } from "store/store";
import * as Api from "pages/document-management/api/api";
import {
  ReportData,
  ResponseDataReport,
} from "pages/document-management/components/reports/model";
import { GoalsData } from "pages/collaborator/components/process/model";

type InitialState = {
  showModalReport: boolean;
  dataReport?: ReportData;
  dataGoal?: GoalsData;
};
const initialState: InitialState = {
  showModalReport: false,
  dataReport: undefined,
};
export const slice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setshowModalReport: (state, action: PayloadAction<boolean>) => {
      state.showModalReport = action.payload;
    },
    setDataReport: (state, action: PayloadAction<ReportData | undefined>) => {
      state.dataReport = action.payload;
    },
    setDataGoalSucess: (state, action: PayloadAction<GoalsData>) => {
      state.dataGoal = action.payload;
    },
  },
});
export const { setshowModalReport } = slice.actions;

export default slice.reducer;

export const getServiceDocumentReport =
  (idGoal: string, startDate: string, endDate: string): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token, collaborator } = getState().auth.dataUser!;
      const { currentDate } = getState().auth;
      const {uid} = collaborator;
      const response: ResponseDataReport = await Api.getServiceDocumentReport(
        uid,
        idGoal,
        startDate,
        endDate,
        currentDate,
        token
      );
      dispatch(
        slice.actions.setDataReport(
          response.data.report_data
            ? {
                ...response.data.report_data,
                start_date: startDate,
                end_date: endDate,
              }
            : undefined
        )
      );
      dispatch(removeProcess(idProcess));

      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };

export const getGoalByID =
  (idGoal: string): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const response: GoalsData = await Api.getGoalByID(idGoal, token);
      dispatch(slice.actions.setDataGoalSucess(response));
      dispatch(removeProcess(idProcess));
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };
