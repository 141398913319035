import {
  IoBagHandleOutline,
  IoPeopleOutline,
  IoBusinessOutline,
} from "react-icons/io5";
import { SideBarLink } from "components/sidebar/model";
import { ROUTES } from "routes/constants";
import { mappingRoute } from "helpers/formatRoute";

const { PLAN_MANAGER, USERS, COMPANIES } =
  ROUTES.DASHBOARD;

export const SIDEBAR_LINKS_SUPERADMIN: SideBarLink[] = [
  // {
  //   url: HOME,
  //   icon: IoHomeOutline,
  //   label: "Inicio",
  //   isDisabled: false,
  // },
  {
    url: mappingRoute(ROUTES.SUPER_ADMINISTRATOR, PLAN_MANAGER),
    icon: IoBagHandleOutline,
    label: "Gestor de planes",
    isDisabled: false,
  },
  {
    url: mappingRoute(ROUTES.SUPER_ADMINISTRATOR, USERS),
    icon: IoPeopleOutline,
    label: "Administradores",
    isDisabled: false,
  },
  // {
  //   url: mappingRoute(ROUTES.SUPER_ADMINISTRATOR, INDICATORS),
  //   icon: IoSpeedometerOutline,
  //   label: "Seguimiento",
  //   isDisabled: false,
  // },
  {
    url: mappingRoute(ROUTES.SUPER_ADMINISTRATOR, COMPANIES),
    icon: IoBusinessOutline,
    label: "Empresas",
    isDisabled: false,
  },
  // {
  //   url: mappingRoute(ROUTES.SUPER_ADMINISTRATOR, PAYMENTS),
  //   icon: IoCashOutline,
  //   label: "Pagos",
  //   isDisabled: false,
  // },
  // {
  //   url: ACCOUNTS,
  //   icon: IoPersonOutline,
  //   label: "Cuentas",
  //   isDisabled: false,
  // },
];

export const listOfFeatures = [
  { id: 1, text: "Generación de actas de reunión" },
  { id: 2, text: "Generación de árbol de discurso" },
  { id: 3, text: "Seguimiento de personas" },
  { id: 4, text: "Seguimiento de procesos" },
  { id: 5, text: "Modulo de gestión de tareas" },
  { id: 6, text: "Modulo de programación de eventos" },
  { id: 7, text: "Estimador de rendimiento grupal e individual" },
  { id: 8, text: "Análisis de sentimiento" },
  { id: 9, text: "Estimador de clima laboral" },
  { id: 10, text: "Generación de informes trimestrales" },
  {
    id: 11,
    text: "Generador de dashboard ejecutivo con métricas de comportamiento y rendimiento organizacional",
  },
  { id: 12, text: "Diagnóstico de personas" },
  { id: 13, text: "Diagnóstico de procesos" },
  { id: 14, text: "Generador de recomendaciones organizacionales" },
  { id: 15, text: "Generador de recomendaciones colaboradores" },
];
