import React from "react";
import { Modal } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import style from "./style/fcdt-modal.module.scss";

type fcdtModalProps = {
  heigth?: string;
  width?: string;
  maxHeigth?: string;
  children: JSX.Element;
  open: boolean;
  handleClose: () => void;
  backgroud?: string;
  buttonClose?: boolean;
};

const FCDTModal = (props: fcdtModalProps) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      className={style.container_principal_modal}
    >
      <div
        className={style.container_principal_childen_modal}
        style={{
          height: props.heigth ? props.heigth : "max-content",
          width: props.width ? props.width : "max-content",
          background: props.backgroud === "none" ? "none" : "",
          boxShadow: props.backgroud === "none" ? "none" : "",
        }}
      >
        {!props.buttonClose ? (
          <div
            className={style.container_close_modal}
            hidden={props.backgroud === "none" ? true : false}
          >
            <IoCloseCircleOutline
              className={style.icon}
              onClick={props.handleClose}
            />
          </div>
        ) : null}

        {props.children}
      </div>
    </Modal>
  );
};

export default FCDTModal;
