import style from "../../style/create-event.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import Button from "components/buttons/fcdt-button";
import { AddEventProps } from "../../model";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";

const AddEvent = (props: AddEventProps) => {
  const {
    handleNavigate,
    handleSubmit,
    onSubmit,
    register,
    processes,
    handleChangeIdProcess,
    goalsFromProcess,
    currentDate,
  } = props;

  return (
    <div className={style.container_add_avent}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          className="fcdt-input"
          placeholder="Agregar titulo"
          required
          minLength={6}
          maxLength={250}
          pattern={REG_EXP.PROCESS_NAME}
          title={TITLE_REG_EXP.PROCESS_NAME}
          {...register("eventName")}
        />

        <textarea
          className="fcdt-input"
          placeholder="Descripción"
          required
          minLength={6}
          maxLength={500}
          {...register("eventDescription")}
        ></textarea>

        <section className={style.section_selects}>
          <div className={style.container_box_select_label}>
            <Title fontSize={17} fontWeight={600}>
              Proceso
            </Title>
            <select
              className="fcdt-select"
              required
              {...register("idProcess")}
              onChange={(e: any) => handleChangeIdProcess(e)}
            >
              <option value="" hidden>
                Selecciones el proceso
              </option>
              {processes.map((item) => {
                return (
                  <option value={item.uid} key={item.uid}>
                    {item.nameProcess}
                  </option>
                );
              })}
            </select>
          </div>

          <div className={style.container_box_select_label}>
            <Title fontSize={17} fontWeight={600}>
              Meta
            </Title>
            <select className="fcdt-select" required {...register("idGoal")}>
              <option value="" hidden>
                Selecciones el proceso
              </option>
              {goalsFromProcess.map((goal) => {
                return (
                  <option value={goal.uid} key={goal.uid}>
                    {goal.nameGoal}
                  </option>
                );
              })}
            </select>
          </div>
        </section>

        <section className={style.section_input_time}>
          <div>
            <Title fontSize={17} fontWeight={600}>
              Fecha
            </Title>
            <input
              type="date"
              className="fcdt-input"
              required
              min={currentDate}
              {...register("date")}
            />
          </div>
          <div>
            <Title fontSize={17} fontWeight={600}>
              Hora de inicio
            </Title>
            <input
              type="time"
              className="fcdt-input"
              required
              {...register("hour_start")}
            />
          </div>
          <div>
            <Title fontSize={17} fontWeight={600}>
              Hora de finalización
            </Title>
            <input
              type="time"
              className="fcdt-input"
              required
              {...register("hour_end")}
            />
          </div>
        </section>

        {/* <section className={style.section_date_hours}>
          <div className={style.container_div_icon_text}>
            <div>
              <IoCalendarNumberOutline className="fcdt-icon-green" size={35} />
              <Paragraph className={style.paragraph}>
                Viernes, 23 febrero
              </Paragraph>
            </div>
            <input type="date" />
          </div>

          <div className={style.container_div_icon_text}>
            <div>
              <IoAlarmOutline className="fcdt-icon-green" size={35} />
              <Paragraph className={style.paragraph}>
                7:00 a.m - 8:00a.m
              </Paragraph>
            </div>
            <input type="date" />
          </div>
        </section>*/}

        <section className={style.section_buttons}>
          <Button
            className="fcdt_btn_without_background_primary"
            type="button"
            onClick={handleNavigate}
          >
            Cancelar
          </Button>
          <Button type="submit">Siguiente</Button>
        </section>
      </form>
    </div>
  );
};

export default AddEvent;
