import FilterContainer from "components/filter/filter-container";
import style from "./style/user.module.scss";
import { UserProps } from "./model";
import { IoCreateOutline, IoPeopleOutline } from "react-icons/io5";
import { BiEnvelope } from "react-icons/bi";
import { AddUser } from "components/add-user/add-user";
import Title from "components/fcdt-title/fcdt-title";

const Users = (props: UserProps) => {
  const { data, handleNewUser, handleSelectUser, isAdmin } = props;
  return (
    <section className={style.user_container}>
      <div className={style.controls}>
        <div className={style.toggle}>
          <AddUser handleClick={handleNewUser} />
        </div>
        <FilterContainer />
      </div>
      <Title fontSize={18} fontWeight={600} className={style.title_table}>
        Lista de usuarios del proceso
      </Title>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Permisos</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((user) => (
            <tr key={user.id}>
              <td>
                <div>
                  <IoPeopleOutline
                    size={31}
                    color="#2f0084"
                    className={style.icon_td}
                  />
                  <p title={user.name} className="fcdt-title-responsive">
                    {user.name}
                  </p>
                </div>
              </td>
              <td>
                <div>
                  <BiEnvelope
                    size={31}
                    color="#2f0084"
                    className={style.icon_td}
                  />
                  <p title={user.email} className="fcdt-title-responsive">
                    {user.email}
                  </p>
                </div>
              </td>
              <td>
                <div className={style.td_icon_description}>
                  <span>
                    <IoCreateOutline
                      size={25}
                      className={`fcdt-icon-gray ${
                        !isAdmin && style.disable_icon
                      }`}
                      onClick={() => handleSelectUser(user, 1)}
                    />
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default Users;
