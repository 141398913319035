import { useState } from "react";
import { FCDTToogleProps } from "./model";
import style from "./style/fcdt-toggle.module.scss";

const FCDTToggle = (props: FCDTToogleProps) => {
  const { handleChange, width, height } = props;
  const { title, titleActive, active = true } = props.data;

  const sizeCircle = height && height - 4;

  const [isActive, setisActive] = useState(active);

  const handleChangeToggle = (e: any) => {
    setisActive(!isActive);
    handleChange(e);
  };

  return (
    <div
      className={`${style.toggle_container} ${props.className}`}
      style={{ height, width }}
    >
      <input
        type="checkbox"
        id="btn"
        checked={isActive}
        onChange={(e) => handleChangeToggle(e)}
      />

      <label htmlFor="btn" style={{ height: sizeCircle, width: sizeCircle }}>
        <span className={`${isActive && style.active}`}>
          {isActive ? titleActive : title}
        </span>
      </label>
    </div>
  );
};

export default FCDTToggle;
