import FCDTModal from "components/modal-notification/fcdt-modal";
import React from "react";
import style from "./styles/modal-add-subtask.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import Button from "components/buttons/fcdt-button";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { ModalAddSubTaskProps } from "./model";

const ModalAddSubTask = (props: ModalAddSubTaskProps) => {
  const {
    openModal,
    handleCloseModal,
    actionSelected,
    listTask,
    handleSelectTask,
    taskSelected,
    handleSubmit,
  } = props;

  const children = (
    <div className={style.container_principal}>
      <Title fontSize={20} fontWeight={700}>
        {" "}
        Añadir como subtarea
      </Title>

      <div className={style.container_name_subtask}>
        <Title fontSize={18} fontWeight={600}>
          Subtreas
        </Title>
        <section>
          <Title fontSize={14} fontWeight={400}>
            {actionSelected}
          </Title>
        </section>
      </div>

      <div className={style.container_name_subtask}>
        <Title fontSize={18} fontWeight={600}>
          Tareas existentes
        </Title>
        <Paragraph className={style.paragraph}>
          Selecciona la tarea a la cual se le va asignar la subtarea
        </Paragraph>
        <section>
          {listTask.map((item) => {
            return (
              <div className={style.container_checkbox_task_name}>
                <div
                  className={`${style.checkbox} ${
                    item.isSelected && style.checkbox_green
                  }`}
                  onClick={() => handleSelectTask(item)}
                >
                  {" "}
                </div>
                <Paragraph>{item.task_name}</Paragraph>
              </div>
            );
          })}
        </section>
      </div>

      <div className={style.container_name_subtask}>
        <Paragraph className={style.paragraph}>
          Detalles de tarea seleccionada
        </Paragraph>

        <section>
          <Paragraph fontWeight={600}>{taskSelected?.task_name}</Paragraph>
          <div className={style.container_circle_subtask_name}>
            <div className={style.circle_box}> </div>
            <Title fontSize={14} fontWeight={400} className={style.description}>
              {actionSelected}
            </Title>
          </div>
        </section>
      </div>

      <div className={style.content_buttons}>
        <Button
          className="btn_cancel"
          children="Atrás"
          width="40"
          type="button"
          onClick={handleCloseModal}
        />
        <Button
          children="Guardar"
          width="40"
          type="submit"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );

  return (
    <FCDTModal
      open={openModal}
      children={children}
      handleClose={handleCloseModal}
      width="45%"
    />
  );
};

export default ModalAddSubTask;
