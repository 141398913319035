import { ChangeEvent, useEffect, useState } from "react";
import AddObjetive from "./add-taks";
import { useDispatch, useSelector } from "react-redux";
import {
  createdTask,
  getAllTaskByIdGoalAndColaborators,
  getInformationTask,
  setAddEditCloseDeleteSubTask,
  setDatesCalendar,
  setDescriptionDeleteTask,
  setIsEditTask,
  setIsModalDates,
  setIsModalDeleteUpdateTask,
  setResetCollaboratorRef,
  setResetSubTask,
  setSelectTask,
  setStatusToggle,
} from "pages/collaborator/store/slices/process";
import { useNavigate, useParams } from "react-router-dom";
import ModalDatesContainer from "../modal-dates/modal-dates-container";
import ModalDeleteTaskContainer from "../modal-delete-task/modal-delete-task-container";
import { RootState } from "store/store";
import { Colaborator, FormAddTaks, TasksByGoal } from "../../model";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { SubTaskts } from "pages/document-management/components/documents/components/recommended-task/components/modal-add_task/model";

const AddTaksContainer = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const {
    dataAllTaskByIdGoal,
    dataAllColaborator,
    datesCalendarRef,
    datesCalendar,
    taskSelected,
    statusTask,
    dataColaboratorSelectedRef,
    isEditTask,
    descriptionDeleteUpdateTask,
    subtasks,
  } = useSelector((state: RootState) => {
    return state.colaborator.process;
  });

  const { register, handleSubmit, reset, setValue, resetField } =
    useForm<FormAddTaks>();

  const [isReload, setisReload] = useState(true);

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const idGoal = params.id_goal!;

  const [dataColaboratorSelected, setdataColaboratorSelected] = useState<
    Colaborator[]
  >([]);

  useEffect(() => {
    setdataColaboratorSelected(dataColaboratorSelectedRef);
  }, [dataColaboratorSelectedRef]);

  useEffect(() => {
    if (idGoal && dataUser && isReload) {
      dispatch(getAllTaskByIdGoalAndColaborators(idGoal));
      setisReload(false);
    }
  }, [idGoal, dataUser, isReload, dispatch]);

  const handleNavigate = () => {
    navigate(-1);
    handleResetDataAddTask();
  };

  const handleChangeToggle = () => {
    if (statusTask.active) {
      dispatch(setIsModalDeleteUpdateTask(true));
      return;
    }
    dispatch(setStatusToggle(true));
  };

  const handleSelectTask = (task: TasksByGoal) => {
    dispatch(setSelectTask(task));

    if (task.isSelected) {
      reset();
      dispatch(setResetCollaboratorRef());
      dispatch(setDatesCalendar(undefined));
      dispatch(setResetSubTask());
      return;
    }

    setValue("taks_name", task.task_name);
    setValue("task_description", task.task_description);
    setValue("task_priority", task.task_priority.toString());
    setValue("task_state", task.execution_status.toString());

    dispatch(getInformationTask(task.uid));
    console.log(task,"aqui");
    
  };

  const handleChangeAutocompleted = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const filterFilms = dataAllColaborator.find((item) => item.label === value);
    if (!filterFilms) return;
    if (value) {
      let newData = [...dataColaboratorSelected];
      const filterRes = newData.find((item) => item.label === value);
      if (filterRes) return;
      newData.push({ label: filterFilms.label, uid: filterFilms.uid });
      setdataColaboratorSelected(newData);
    }
  };

  const handleDeleteColaborator = (colaborator: Colaborator) => {
    const newData = dataColaboratorSelected.filter(
      (item) => item.uid !== colaborator.uid
    );
    setdataColaboratorSelected(newData);
  };

  const handleShowModalDates = () => {
    dispatch(setIsModalDates(true));
  };

  

  const is_admin = dataUser?.collaborator.is_admin;
  const is_leader = dataUser?.collaborator.is_leader;
  const disabled_autentications = is_admin || is_leader ? true : false;
  
  const onSubmit = async (values: FormAddTaks) => {
    if (!datesCalendar&&disabled_autentications) {
      toast.error("Completar las fechas en el calendario", {duration: 7000, position:"top-center"});
      return;
    }
    if (dataColaboratorSelected.length === 0) {
      toast.error("Agregar al menos un participante a la tarea", {duration: 7000, position:"top-center"});
      return;
    }

    if (taskSelected && !isEditTask) {
      if (
        taskSelected?.task_name !== values.taks_name ||
        taskSelected.task_description !== values.task_description ||
        taskSelected.task_priority.toString() !== values.task_priority
      ) {
        dispatch(setIsEditTask(true));
        dispatch(setIsModalDeleteUpdateTask(true));
        return;
      }
    }

    const colaboratos = dataColaboratorSelected.map((item) => {
      return item.uid;
    });

    if (dataColaboratorSelectedRef.length > 0 && !isEditTask && taskSelected) {
      if (dataColaboratorSelectedRef !== dataColaboratorSelected) {
        dispatch(setIsEditTask(true));
        dispatch(setIsModalDeleteUpdateTask(true));
        return;
      }
    }

    if (datesCalendarRef !== datesCalendar && !isEditTask && taskSelected) {
      dispatch(setIsEditTask(true));
      dispatch(setIsModalDeleteUpdateTask(true));
      return;
    }

    console.log({ values });

    const res: any = await dispatch(createdTask(values, idGoal, colaboratos));
    if (!res?.ok) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    toast.success(
      `¡Tarea ${!taskSelected ? "creada" : "actualizada"} exitosamente!`
      , {duration: 7000, position:"top-center"});
    handleResetDataAddTask();
    setisReload(true);
  };

  const handleResetDataAddTask = () => {
    reset();
    dispatch(setResetCollaboratorRef());
    dispatch(setDatesCalendar(undefined));
    dispatch(setSelectTask(undefined));
    dispatch(setIsEditTask(false));
    dispatch(setDescriptionDeleteTask(""));
    dispatch(setResetSubTask());
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      dispatch(
        setAddEditCloseDeleteSubTask({
          service: 1,
          value: e.target.value,
          task: undefined,
        })
      );

      resetField("subtask");
    }
  };

  const handleGeneralKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChangeTask = (
    e: ChangeEvent<HTMLInputElement>,
    task: SubTaskts
  ) => {
    e.preventDefault();

    dispatch(
      setAddEditCloseDeleteSubTask({
        service: 2,
        value: e.target.value,
        task,
      })
    );
  };

  const handleEditCloseDelete = (task: SubTaskts, service: number) => {
    dispatch(setAddEditCloseDeleteSubTask({ service, value: "", task }));
  };

  return (
    <>
      <AddObjetive
        handleComeBack={handleNavigate}
        dataToggle={statusTask}
        taskSelected={taskSelected}
        handleChangeToggle={handleChangeToggle}
        handleShowModalDates={handleShowModalDates}
        dataAllTasks={dataAllTaskByIdGoal}
        dataAllColaborator={dataAllColaborator}
        handleSelectTask={handleSelectTask}
        handleChangeAutocompleted={handleChangeAutocompleted}
        dataColaboratorSelected={dataColaboratorSelected}
        handleDeleteColaborator={handleDeleteColaborator}
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        handleResetDataAddTask={handleResetDataAddTask}
        descriptionDeleteUpdateTask={descriptionDeleteUpdateTask}
        subTasks={subtasks}
        handleKeyPress={handleKeyPress}
        handleChangeTask={handleChangeTask}
        handleEditCloseDelete={handleEditCloseDelete}
        handleGeneralKeyPress={handleGeneralKeyPress}
      />
      <ModalDatesContainer />
      <ModalDeleteTaskContainer />
    </>
  );
};

export default AddTaksContainer;
