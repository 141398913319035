import { useSelector } from "react-redux";
import { RootState } from "store/store";
import Dialog from "components/modals/dialog";
import { NewGoal } from "components/modals/components/new-goal";
import { NewLeader } from "components/modals/components/new-leader";
import { ProcessProps } from "./models";
import style from "./style/process.module.scss";
import { NewProcess } from "components/modals/components/new-process";
import FilterContainer from "components/filter/filter-container";
import { useEffect, useState } from "react";
import { Pagination } from "components/pagination/pagination";
import { GoPlusCircle } from "react-icons/go";
import { IoCreateOutline } from "react-icons/io5";
import Title from "components/fcdt-title/fcdt-title";

export const Process = (props: ProcessProps) => {
  const {
    handleOpenDialogGoal,
    handleOpenDialogLeader,
    handleOpenDialogProcess,
    process,
    handleUpdateProcess,
  } = props;
  const { isOpenNewGoal, isOpenNewLeader, isOpenNewProcess } = useSelector(
    (state: RootState) => {
      return state.admin.process;
    }
  );
  const totalPages = process ? Math.ceil(process.length / 3) : 1;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [process]);
  const startIndex = (currentPage - 1) * 3;
  const endIndex = Math.min(startIndex + 3, process?.length || 0);
  const itemsToShow = process ? process.slice(startIndex, endIndex) : [];

  return (
    <>
      <Dialog
        isOpen={isOpenNewGoal}
        onClose={handleOpenDialogGoal}
        styleClose={false}
        customClass={style.content_new_goal}
      >
        <NewGoal />
      </Dialog>
      <Dialog
        isOpen={isOpenNewLeader}
        onClose={handleOpenDialogLeader}
        styleClose={false}
      >
        <NewLeader />
      </Dialog>
      <Dialog
        isOpen={isOpenNewProcess}
        onClose={handleOpenDialogProcess}
        styleClose={false}
        customClass={style.content_new_process}
      >
        <NewProcess />
      </Dialog>

      <section className={style.process_container}>
        <div className={style.controls}>
          <div className={style.toggle}>
            <button
              type="button"
              className={style.btn_add}
              onClick={() => handleOpenDialogProcess()}
            >
              <GoPlusCircle size={30} color="#00de97" />
              Añadir Proceso
            </button>
          </div>

          <FilterContainer />
        </div>

        <Title fontSize={18} fontWeight={600} className={style.title_table}>
          Lista de procesos asignados
        </Title>

        <div className={style.content}>
          <table>
            {itemsToShow.length === 0 ? (
              <tbody>
                <tr>
                  <td>
                    <p>No hay coincidencias con tu búsqueda</p>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <th>ID</th>
                  <th>Nombre del proceso</th>
                  <th>Descripción</th>
                  <th>Acciones</th>
                </tr>
                {itemsToShow.map((process, index) => (
                  <tr key={process?.proceso?.uid}>
                    <td>{index + 1}</td>
                    <td>{process?.proceso?.process_name}</td>
                    <td className={style.description}>
                      {process?.proceso?.descriptive_paragraph}
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => handleUpdateProcess(process)}
                      >
                        <IoCreateOutline size={25} className="fcdt-icon-gray" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <Pagination
          events={process}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </section>
    </>
  );
};
