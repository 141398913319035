import { useEffect, useState } from "react";
import Calendar from "./calendar";
import { useDispatch, useSelector } from "react-redux";
import { currentAllDate } from "./contants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constants";
import {
  getDataMetetings,
  resetCalendar,
  setdateSelected,
  setOpenModalHours,
} from "pages/calendar/store/slices/calendar";
import { RootState } from "store/store";
import CalendarComponent from "../modal-calendar-events/modal-calendar-events";
import { formatDate } from "helpers/formatDate";
import ModalShowMeetingContainer from "../modal-show-meeting/modal-show-meeting-container";
import { resetValuesCreateEvent } from "pages/calendar/store/slices/create-event";
import ModalDeleteMeetingContainer from "../modal-delete-meeting/modal-delete-meeting-container";

const CalendarContainer = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(ROUTES.CALENDAR.CHILDRENS.CREATE_EVENT);
    dispatch(resetValuesCreateEvent());
    dispatch(resetCalendar());
  };

  const { dataMeetings, isFeching, isReloadNeeded } = useSelector(
    (state: RootState) => {
      return state.calendar.calendar;
    }
  );

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    if (isReloadNeeded && !isFeching && dataUser) {
      dispatch(getDataMetetings());
    }
  }, [isReloadNeeded, isFeching, dataUser, dispatch]);

  const selectedDates = dataMeetings.map((item) => {
    const date = new Date(item.meeting_start_date);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    return `${month}/${day}/${year}`;
  });

  const [currentDate, setCurrentDate] = useState(currentAllDate);

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 1)
    );
  };

  const handleDateClick = (day: number) => {
    const date = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      day
    );

    const datestring = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

    const findDate = selectedDates.find((item) => item === datestring);

    if (findDate) {
      dispatch(setOpenModalHours(true));
      dispatch(setdateSelected(formatDate(date)));
    }

    // when the date is found, we have to dispatch modal hours

    // setSelectedDates((prevDates) =>
    //   prevDates.includes(date)
    //     ? prevDates.filter((d) => d !== date)
    //     : [...prevDates, date]
    // );

    // console.log({ selectedDates });
  };

  return (
    <>
      <Calendar
        handleNavigate={handleNavigate}
        currentDate={currentDate}
        selectedDates={selectedDates}
        handlePrevMonth={handlePrevMonth}
        handleNextMonth={handleNextMonth}
        handleDateClick={handleDateClick}
        isLeader={
          dataUser
            ? dataUser.collaborator.is_admin || dataUser.collaborator.is_leader
            : false
        }
      />
      {/* <ModalHoursContainer /> */}
      <CalendarComponent />
      <ModalShowMeetingContainer />
      <ModalDeleteMeetingContainer />
    </>
  );
};

export default CalendarContainer;
