import axios from "axios";
import { APIURL, COMPANIES_PORT } from "config/APIURL";
import { RequestDeleteCompany } from "../model";

export const getCompanies = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/empresa`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const updateCompany = async (
  id: string,
  status: boolean,
  token: string
) => {
  const body: RequestDeleteCompany = {
    id,
    status,
  };

  try {
    const response = await axios({
      method: "PUT",
      url: `${APIURL}/api/empresa/eliminar`,
      headers: {
        "x-token": token,
      },
      data: body,
    });
    return response?.data;
  } catch (error: any) {
    return error.response.data;
  }
};
