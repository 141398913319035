import { FCDTTitleProps } from "./model";
import style from "./style/fcdt-title.module.scss";

const Title = (props: FCDTTitleProps) => {
  return (
    <p
      className={`fcdt-title-primary ${style.container_title} ${props.className}`}
      style={{
        ...props.style,
        fontWeight: props.fontWeight,
        fontSize: props.fontSize,
        width: `${props.width}%`,
        color: props.color,
      }}
    >
      {props.children}
    </p>
  );
};

export default Title;
