import FilterContainer from "components/filter/filter-container";
import { Data } from "components/toggle/model";
import { Toggle } from "components/toggle/toggle";
import style from "./style/notification.module.scss";
import { NotificationComponentProps } from "./model";
import { useEffect, useState } from "react";
import { Pagination } from "components/pagination/pagination";
import { formatDate } from "helpers/formatDate";
import { notificationCategory } from "helpers/typeNotification";
import { IoCreateOutline } from "react-icons/io5";
import {
  setIdTaskSelected,
  setShowModalDatesNotification,
} from "pages/collaborator/store/slices/notification";
import { useDispatch } from "react-redux";
import Title from "components/fcdt-title/fcdt-title";

export const Notification = (props: NotificationComponentProps) => {
  const { notifications, toggleChecked } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const totalPages = notifications ? Math.ceil(notifications.length / 3) : 1;
  const data: Data = {
    title: "Archivados",
    titleActive: "Pendientes",
    active: false,
  };

  const startIndex = (currentPage - 1) * 3;
  const endIndex = Math.min(startIndex + 3, notifications?.length || 0);
  const itemsToShow = notifications
    ? notifications.reverse().slice(startIndex, endIndex)
    : [];
  
  useEffect(() => {
    setCurrentPage(1);
  }, [notifications]);

  return (
    <section className={style.notification_container}>
      <div className={style.controls}>
        <Toggle data={data} />
        <FilterContainer />
      </div>
      <Title fontSize={18} fontWeight={600} className={style.title_table}>
        {`Lista de notificaciones ${
          !toggleChecked ? "pendientes" : "revisadas"
        }`}
      </Title>
      <div className={style.content}>
        <table>
          <thead></thead>
          {itemsToShow.length === 0 ? (
            <p>
              {notifications?.length === 0
                ? "No hay notificaciones"
                : "No hay coincidencias con tu búsqueda"}
            </p>
          ) : (
            <>
              <tr>
                <th className={style.th_id_level_action}>
                  Tipo de notificación
                </th>
                <th className={style.th_description}>Descripción</th>
                <th className={style.th_id_level_action}>Remitente</th>
                <th className={style.th_id_level_action}>
                  Fecha de notificación
                </th>
                <th>Acción</th>
              </tr>
              {itemsToShow.map((notification) => (
                <tr key={notification._id}>
                  <td>
                    {notificationCategory(
                      notification.id_notification_category
                    )}
                  </td>

                  <td className={style.td_description}>
                    <div>{notification.notification_message}</div>
                  </td>
                  <td className="fcdt-title-responsive">{notification.assigner_info.name_in_meeting_platform}</td>
                  <td className={style.transmitter}>
                    {formatDate(notification.createdAt)}
                  </td>
                  <td
                    className={
                      notification.id_notification_category !== 3
                        ? style.td_disable_icon
                        : ""
                    }
                  >
                    <IoCreateOutline
                      size={20}
                      className="fcdt-icon-gray"
                      onClick={() => {
                        dispatch(
                          setIdTaskSelected({
                            idTask: notification.id_target_object,
                            idDate: notification.task_dates,
                          })
                        );
                        dispatch(setShowModalDatesNotification(true));
                      }}
                    />
                  </td>
                </tr>
              ))}
            </>
          )}
        </table>
      </div>
      <br />
      <Pagination
        notifications={notifications}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </section>
  );
};
