import { ChangeEvent, useState } from "react";
import AddPersons from "./add-persons";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { FormAddPerson } from "../../model";
import {
  setCurrentIdAddEvent,
  setReloadCalendar,
} from "pages/calendar/store/slices/calendar";
import { RootState } from "store/store";
import {
  createMeeting,
  resetValuesCreateEvent,
  setDataColaboratorSelected,
  setDataExternalColaboratorSelected,
  setDeleteColaborator,
  setSelectReviwer,
} from "pages/calendar/store/slices/create-event";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { mappingRoute } from "helpers/formatRoute";
import ModalInfoReviwer from "pages/calendar/components/modal-review-minute/modal-info-reviwer";

const AddPersonsContainer = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const {
    dataAllColaborator,
    dataColaboratorSelected,
    dataExternalColaboratorSelected,
    reviwer,
  } = useSelector((state: RootState) => {
    return state.calendar.createEvent;
  });

  const { register, handleSubmit, getValues, resetField } =
    useForm<FormAddPerson>();

  const { currentPath } = useSelector((state: RootState) => {
    return state.auth;
  });

  const [showModalReviwer, setshowModalReviwer] = useState(false);
  const [isOpened, setisOpened] = useState(false);

  const handleNavigate = () => {
    dispatch(setCurrentIdAddEvent(1));
  };

  const handleChangeAutocompleted = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    const filterFilms = dataAllColaborator.find((item) => item.label === value);
    if (!filterFilms) return;
    if (value) {
      let newData = [...dataColaboratorSelected];
      const filterRes = newData.find((item) => item.label === value);
      if (filterRes) return;
      newData.push({
        label: filterFilms.label,
        uid: filterFilms.uid,
        isReviwer: false,
      });
      dispatch(setDataColaboratorSelected(newData));
    }
  };

  const handleSelectReviwer = (uid: string) => {
    dispatch(setSelectReviwer(uid));
  };

  const handleDeleteColaborator = (value: string, isExternal: boolean) => {
    dispatch(setDeleteColaborator({ value, isExternal }));
  };

  // Logic for input email

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      const email = getValues().email;
      if (!validateEmail(email)) return;
      const newData = [...dataExternalColaboratorSelected];
      const findEmail = newData.find((item) => item === email);
      e.preventDefault();
      if (findEmail) {
        toast.error("Correo ya se encuentra en la lista", {duration: 7000, position:"top-center"});
        return;
      }
      dispatch(setDataExternalColaboratorSelected([...newData, email]));
      resetField("email");
    }
  };

  const validateEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
    return regex.test(email);
  };

  const onSubmit = () => {};

  const customHandleSubmit = async () => {
    if (reviwer.length === 0 && !isOpened) {
      setshowModalReviwer(true);
      setisOpened(true);
      return;
    }
    const res = await dispatch(createMeeting());
    if (!res?.ok) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    navigate(
      mappingRoute(
        `/${currentPath}/*`,
        mappingRoute(ROUTES.CALENDAR.PARENT, ROUTES.CALENDAR.CHILDRENS.EVENTS)
      )
    );

    toast.success(res?.msg, {duration: 7000, position:"top-center"});
    dispatch(setReloadCalendar());
    dispatch(resetValuesCreateEvent());
    handleNavigate();
  };

  const handleCloseModal = () => {
    setshowModalReviwer(false);
  };

  return (
    <div>
      <AddPersons
        handleNavigate={handleNavigate}
        handleSubmit={handleSubmit}
        customHandleSubmit={customHandleSubmit}
        onSubmit={onSubmit}
        register={register}
        dataColaborator={dataAllColaborator}
        dataColaboratorSelected={dataColaboratorSelected}
        handleSelectReviwer={handleSelectReviwer}
        handleChangeAutocompleted={handleChangeAutocompleted}
        handleDeleteColaborator={handleDeleteColaborator}
        handleKeyPress={handleKeyPress}
        dataExternalColaboratorSelected={dataExternalColaboratorSelected}
      />
      <ModalInfoReviwer
        openModal={showModalReviwer}
        handleCloseModal={handleCloseModal}
      />
      ;
    </div>
  );
};

export default AddPersonsContainer;
