import { useForm } from "react-hook-form";
import { FormLoginType } from "./model";
import Login from "./login";
import { loginRequestPost } from "store/slices/login";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { mappingRoute } from "helpers/formatRoute";

const LoginContainer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormLoginType>();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const onSubmit = async (values: FormLoginType) => {
    const response: any = await dispatch(loginRequestPost(values));

    if (!response) return;

    if (!response?.ok && !response?.collaborator && !response.superadmin) {
      toast.error(response?.msg, {duration: 7000, position:"top-center"});
      return;
    }
    if (response?.ok === false) {
      toast.error(response?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    if (response?.superadmin) {
      navigate(
        mappingRoute(ROUTES.SUPER_ADMINISTRATOR, ROUTES.DASHBOARD.PLAN_MANAGER)
      );
      return;
    }

    if (response?.collaborator) {
      if (!response.collaborator.is_admin) {
        if (!response.collaborator.is_license_active) {
          toast.error(
            "Su licencia no está activa, comuníquese con el administrador"
            , {duration: 7000, position:"top-center"});
          return;
        }
        navigate(
          mappingRoute(
            ROUTES.COLLABORATOR.PARENT,
            ROUTES.DASHBOARD.NOTIFICATION
          )
        );
      } else {
        if (!response.collaborator.is_license_active) {
          console.log(`/${response.collaborator.id_company}/planes`);

          navigate(`/${response.collaborator.id_company}/planes`);
          return;
        }
        navigate(
          mappingRoute(ROUTES.ADMINISTRATOR, ROUTES.DASHBOARD.NOTIFICATION)
        );
      }
    }
  };

  return (
    <Login
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      errors={errors}
    />
  );
};

export default LoginContainer;
