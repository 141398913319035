import React from "react";
import style from "./style/profile-nav.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import Icon from "assets/backgroud_points.webp";

const ProfileNav = () => {
  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  return (
    <header className={style.profile}>
      <p>
        {dataUser?.collaborator
          ? dataUser.collaborator.name_in_meeting_platform
          : "Super administrator"}
      </p>
      <img src={Icon} alt="Profile" width={52} height={52} />
    </header>
  );
};

export default ProfileNav;
