import {
  IoNotificationsOutline,
  IoPeopleOutline,
  IoCalendarNumberOutline,
  IoDocumentAttachOutline,
  IoAlbumsOutline,
  IoDocumentOutline,
  IoColorFilterOutline,
  IoEaselOutline,
  IoSpeedometerOutline,
} from "react-icons/io5";
import { SideBarLink } from "../model";
import { ROUTES } from "routes/constants";
import { mappingRoute } from "helpers/formatRoute";
import { BiSearchAlt } from "react-icons/bi";

const { NOTIFICATION, USERS, PROCESS, DATES_FOLLOWUP, KNOWLEDGE } =
  ROUTES.DASHBOARD;
const { EVENTS } = ROUTES.CALENDAR.CHILDRENS;
const { DOCUMENTS, SPEECH_TREE, PROCESS_REPORT } =
  ROUTES.DOCUMENT_MANAGEMENT.CHILDRENS;

export const SIDEBAR_LINKS: SideBarLink[] = [
  {
    url: mappingRoute(ROUTES.ADMINISTRATOR, NOTIFICATION),
    icon: IoNotificationsOutline,
    label: "Notificaciones",
    isDisabled: false,
  },
  {
    url: mappingRoute(ROUTES.ADMINISTRATOR, USERS),
    icon: IoPeopleOutline,
    label: "Usuarios",
    isDisabled: false,
  },
  {
    url: mappingRoute(ROUTES.ADMINISTRATOR, PROCESS),
    icon: IoDocumentAttachOutline,
    label: "Procesos",
    isDisabled: false,
  },
  {
    url: mappingRoute(
      ROUTES.ADMINISTRATOR,
      mappingRoute(ROUTES.CALENDAR.PARENT, EVENTS)
    ),
    icon: IoCalendarNumberOutline,
    label: "Calendario",
    isDisabled: false,
  },
  {
    url: mappingRoute(ROUTES.ADMINISTRATOR, DATES_FOLLOWUP),
    icon: IoSpeedometerOutline,
    label: "Seguimiento",
    isDisabled: false,
  },
  {
    url: "",
    icon: IoAlbumsOutline,
    label: "Gestión Documental",
    isDisabled: false,
    isSelect: true,
    dataSelect: [
      {
        url: mappingRoute(
          ROUTES.ADMINISTRATOR,
          mappingRoute(ROUTES.DOCUMENT_MANAGEMENT.PARENT, DOCUMENTS)
        ),
        icon: IoDocumentOutline,
        label: "Actas",
        isDisabled: false,
      },
      {
        url: mappingRoute(
          ROUTES.ADMINISTRATOR,
          mappingRoute(ROUTES.DOCUMENT_MANAGEMENT.PARENT, SPEECH_TREE)
        ),
        icon: IoColorFilterOutline,
        label: "Árbol de discurso",
        isDisabled: false,
      },
      {
        url: mappingRoute(
          ROUTES.ADMINISTRATOR,
          mappingRoute(ROUTES.DOCUMENT_MANAGEMENT.PARENT, PROCESS_REPORT)
        ),
        icon: IoEaselOutline,
        label: "Reportes",
        isDisabled: false,
      },
    ],
  },
  {
    url: mappingRoute(ROUTES.ADMINISTRATOR, KNOWLEDGE),
    icon: BiSearchAlt,
    label: "Base de conocimiento",
    isDisabled: false,
  },
];
