// CalendarComponent.tsx
import React from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import FCDTModal from "components/modal-notification/fcdt-modal";
import {
  getDataMetetingsById,
  setOpenModalHours,
  setSelectEvent,
  setshowModalDescriptionMeeting,
} from "pages/calendar/store/slices/calendar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { MyEvent } from "../calendar/model";

import { es } from "date-fns/locale";

const locales = {
  es: es,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

const CalendarComponent: React.FC = () => {
  const dispatch: any = useDispatch();

  const { showModalHours, events, dateSelected } = useSelector(
    (state: RootState) => {
      return state.calendar.calendar;
    }
  );

  const handleSelectEvent = (event: MyEvent) => {
    dispatch(setSelectEvent(event));
    dispatch(setOpenModalHours(false));
    dispatch(setshowModalDescriptionMeeting(true));
    dispatch(getDataMetetingsById());
  };

  const handleCloseModal = () => {
    dispatch(setOpenModalHours(false));
  };

  const children = (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        selectable
        onSelectEvent={handleSelectEvent}
        defaultView={Views.DAY}
        date={dateSelected}
        style={{
          height: 500,
          width: 900,
          fontFamily: "Poppins",
          marginInline: "20px",
          fontSize: "16px",
        }}
      />
    </div>
  );

  return (
    <FCDTModal
      open={showModalHours}
      children={children}
      handleClose={handleCloseModal}
    />
  );
};

export default CalendarComponent;
