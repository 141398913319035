import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DocumentsAllField,
  DocumentsTable,
  MinutesContent,
  ResponseMinutes,
} from "pages/document-management/model";
import { displayLoader, removeProcess } from "store/slices/loading";
import { AppThunk } from "store/store";
import * as Api from "pages/document-management/api/api";
import { DataToggle } from "components/fcdy-toggle/model";
import {
  ValuesFirstFormDocument,
  ValuesSecondFormDocument,
} from "pages/document-management/components/documents/components/edit-document/components/model";
import { downloadFile } from "helpers/downloadFile";
import { lineBreaks } from "helpers/lineBreaks";

type InitialState = {
  isReloadNeeded: boolean;
  isFeching: boolean;
  dataDocuments: DocumentsAllField[];
  dataDocumentsTable: DocumentsTable[];
  minutesContentSelected?: MinutesContent;
  dataToggle: DataToggle;
  isModalLoadDocument: boolean;
  isModalViewDocument: boolean;
  actualIdFormDocument: number;
  dataFirstFormDocument?: ValuesFirstFormDocument;
  dataSecondFormDocument?: ValuesSecondFormDocument;
};
const initialState: InitialState = {
  isReloadNeeded: true,
  isFeching: false,
  dataDocuments: [],
  dataDocumentsTable: [],
  minutesContentSelected: undefined,
  dataToggle: {
    active: false,
    title: "Sin revisar",
    titleActive: "Revisados",
  },
  isModalLoadDocument: false,
  isModalViewDocument: false,
  actualIdFormDocument: 1,
  dataFirstFormDocument: undefined,
  dataSecondFormDocument: undefined,
};
export const slice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocumentsInit: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = true;
    },
    setDocumentsSucess: (state, actions: PayloadAction<MinutesContent[]>) => {
      state.dataDocuments = actions.payload.map((item, index) => {
        const newDate = new Date(item.associated_meeting.meeting_start_date);
        return {
          id: index + 1,
          document_name: item.subject_discussion,
          uid: item._id,
          document_date: newDate.toISOString().split("T")[0],
          isRevised: item.is_revised,
          id_process_goal: item.associated_meeting.id_process_goal,
          id_meeting: item.associated_meeting.uid,
        };
      });

      state.dataDocumentsTable = state.dataDocuments.filter(
        (item) => !item.isRevised
      );
      state.isReloadNeeded = false;
      state.isFeching = false;
    },
    setDocumentByIdSucess: (
      state,
      action: PayloadAction<MinutesContent | undefined>
    ) => {
      if (!action.payload) {
        state.minutesContentSelected = undefined;
        return;
      }

      state.minutesContentSelected = {
        ...action.payload!,
        summary: lineBreaks(action.payload?.subject_discussion!),
        commitments: lineBreaks(action.payload?.commitments!),
        conclusions: lineBreaks(action.payload?.conclusions!),
      };
    },
    setDocumentCompletedForm: (
      state,
      action: PayloadAction<MinutesContent>
    ) => {
      const {
        subject_discussion,
        associated_meeting,
        summary,
        commitments,
        conclusions,
      } = action.payload;

      const newDate = new Date(associated_meeting.meeting_start_date);

      state.dataFirstFormDocument = {
        minuteName: associated_meeting.meeting_name,
        date: newDate.toISOString().split("T")[0],
        minuteCreated: associated_meeting.created_by,
        minuteDiscution: subject_discussion,
      };

      state.dataSecondFormDocument = {
        minutesSummay: lineBreaks(summary),
        minutescommitment: lineBreaks(commitments),
        minuteConclusion: lineBreaks(conclusions),
      };
    },
    setDataToggle: (state) => {
      state.dataToggle = {
        ...state.dataToggle,
        active: !state.dataToggle.active,
      };

      state.dataDocumentsTable = state.dataDocuments.filter(
        (item) => item.isRevised === state.dataToggle.active
      );
    },
    setIsModalLoadDocument: (state, actions: PayloadAction<boolean>) => {
      state.isModalLoadDocument = actions.payload;
    },
    setIsModalViewDocument: (state, actions: PayloadAction<boolean>) => {
      state.isModalViewDocument = actions.payload;
    },
    setActualIdFormDocument: (state, action: PayloadAction<number>) => {
      state.actualIdFormDocument = action.payload;
    },
    setDataFirstFormDocument: (
      state,
      action: PayloadAction<ValuesFirstFormDocument>
    ) => {
      state.dataFirstFormDocument = action.payload;
      state.actualIdFormDocument = 2;
    },
    setReloadDocument: (state) => {
      state.isReloadNeeded = true;
    },
  },
});
export const {
  setDataToggle,
  setIsModalLoadDocument,
  setIsModalViewDocument,
  setActualIdFormDocument,
  setDataFirstFormDocument,
  setDocumentByIdSucess,
  setReloadDocument,
} = slice.actions;

export default slice.reducer;

export const getDataDocuments = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const { token } = getState().auth.dataUser!;
    const response: ResponseMinutes = await Api.getAllDocuments(token);
    dispatch(slice.actions.setDocumentsSucess(response.minutesContents));
    dispatch(removeProcess(idProcess));
    return true;
  } catch (error) {
    dispatch(removeProcess(idProcess));
    return false;
  }
};

export const getDocumentById =
  (id: string, isForm?: boolean): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;
      const response = await Api.getDocumentByID(id, token);
      if (isForm) {
        dispatch(slice.actions.setDocumentCompletedForm(response));
      } else {
        dispatch(slice.actions.setDocumentByIdSucess(response));
      }
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const EditDocument =
  (secondForm: ValuesSecondFormDocument, idMinute: string): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;
      const { dataFirstFormDocument, minutesContentSelected } =
        getstate().documentsManagement.documents;
      const response = await Api.editDocument(
        dataFirstFormDocument!,
        secondForm,
        idMinute,
        minutesContentSelected?.associated_meeting.uid!,
        token
      );

      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const downloadDocument = (): AppThunk => async (dispatch, getstate) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const { token } = getstate().auth.dataUser!;
    const { minutesContentSelected } = getstate().documentsManagement.documents;
    const response = await Api.downloadDocument(
      minutesContentSelected?.associated_meeting.uid!,
      token
    );
    dispatch(removeProcess(idProcess));

    if (response.data.file_path) {
      downloadFile(
        response.data.file_path,
        `Acta_${minutesContentSelected?._id}.pdf`
      );
    }

    return response;
  } catch (error) {
    console.log({ error });
    dispatch(removeProcess(idProcess));
    return false;
  }
};
