import React, { useEffect, useState } from "react";
import style from "./style/report.module.scss";
import { BiSolidRectangle } from "react-icons/bi";
import {
  IoCalendarNumberOutline,
  IoCheckmarkCircle,
  IoDownloadOutline,
  IoEyeOutline,
} from "react-icons/io5";
import Button from "components/buttons/fcdt-button";
import Title from "components/fcdt-title/fcdt-title";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { ReportsProps } from "./model";
import { formatDate } from "helpers/formatDate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { get_generate_monitoring_report } from "pages/document-management/api/api";

const Reports = (props: ReportsProps) => {
  const { data, register, handleSubmit, onSubmit, handleNavigate } = props;

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });
  const [datainfo, setdatainfo]= useState([]);
  console.log(datainfo);
  let auxi_data_info
  useEffect (() => {
    if (dataUser) {
      auxi_data_info=get_generate_monitoring_report(dataUser?.collaborator.id_person,dataUser?.token);
      console.log(auxi_data_info);
      
      // setdatainfo(auxi_data_info?.length>0?auxi_data_info:[])
    }
  }, [dataUser]);
  return (
    <section className={style.speech_tree_container}>
      <Title className={style.title}>Generación de informe1</Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={style.container_content}
      >
        <p>
          <BiSolidRectangle size={30} color="#00de97" />
          {data.goal_title}
        </p>
        <p>
          <IoCalendarNumberOutline size={30} />
          Fecha de cumplimiento de la meta:{" "}
          <span>{formatDate(data.start_date)}</span>
        </p>

        <hr />
        <h3>Lista de informes generados</h3>
        <div
          className={`${style.content_collaborators} ${style.container_collaborators_block}`}
        >
          <div className={style.container_generated_minutes}>
            <IoCheckmarkCircle className={style.icon_checkmark} size={22} />
            <Paragraph
              fontWeight={300}
              className={style.paragraph_list_minutes}
            >
              Informe de 2024-04-01 al 2024-05-30
            </Paragraph>
          </div>
          <div className={style.container_generated_minutes}>
            <IoCheckmarkCircle className={style.icon_checkmark} size={22} />
            <Paragraph
              fontWeight={300}
              className={style.paragraph_list_minutes}
            >
              Informe de 2024-05-17 al 2024-06-18
            </Paragraph>
          </div>
        </div>

        <hr />
        <h3>Selecciona las fechas de interés</h3>
        <div className={style.content_collaborators}>
          <div>
            <Paragraph fontWeight={300}>Inicio</Paragraph>
            <input
              type="date"
              className="fcdt-input"
              required
              {...register("start_date")}
            />
          </div>
          <div>
            <Paragraph fontWeight={300}>Finalización</Paragraph>
            <input
              type="date"
              className="fcdt-input"
              required
              {...register("end_date")}
            />
          </div>
        </div>
        <hr />
        <div className={style.actions}>
          <button type="submit">Ver informe</button>
          <div>
            <IoDownloadOutline size={28} color="#545252" />
            <span>Descargar</span>
          </div>
        </div>
      </form>

      <div className={style.action_step}>
        <Button
          className="btn_cancel"
          children="Atrás"
          onClick={() => handleNavigate(1)}
        />
        <Button type="button" onClick={() => handleNavigate(2)}>
          Aceptar
        </Button>
      </div>
    </section>
  );
};

export default Reports;
