import FCDTModal from "components/modal-notification/fcdt-modal";
import { ModalViewDocumentProps } from "pages/document-management/model";
import style from "./style/modal-view-document.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import {
  IoCalendarNumberOutline,
  IoDownloadOutline,
  IoPersonOutline,
} from "react-icons/io5";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { formatDate } from "helpers/formatDate";

const ModalViewDocument = (props: ModalViewDocumentProps) => {
  const { open, handleCloseModal, handleDownload, data } = props;

  let newDate: string = formatDate(data.associated_meeting.meeting_start_date);

  const children = (
    <div className={style.container_principal}>
      <Paragraph className={style.paragrahp_download}>
        <div className={style.div} onClick={handleDownload}>
          <IoDownloadOutline size={25} /> Descargar
        </div>
      </Paragraph>

      <Title fontSize={18} fontWeight={600}>
        {data.associated_meeting.meeting_name}
      </Title>

      <div className={style.container_box_icon_description}>
        <section>
          <IoCalendarNumberOutline color="#606263" size={30} />
          <Paragraph fontWeight={700} className={style.paragrahp}>
            Fecha de la reunión:
          </Paragraph>
          <Paragraph className={style.paragrahp}>{newDate}</Paragraph>
        </section>
        <section>
          <IoPersonOutline size={30} color="#606263" />
          <Paragraph fontWeight={700} className={style.paragrahp}>
            Creador del evento:
          </Paragraph>
          <Paragraph className={style.paragrahp}></Paragraph>
        </section>
      </div>

      <section className={style.section_paragraph}>
        <Paragraph className={style.paragrahp}>
          <strong>Resumen: </strong> <span>{data.summary}</span>
        </Paragraph>
      </section>

      <section className={style.section_paragraph}>
        <Paragraph className={style.paragrahp}>
          <strong>Compromiso: </strong>{" "}
          <textarea disabled>{data.commitments}</textarea>
        </Paragraph>
      </section>

      <section className={style.section_paragraph}>
        <Paragraph className={style.paragrahp}>
          <strong>Conclusiones: </strong>{" "}
          <textarea disabled>{data.conclusions}</textarea>
        </Paragraph>
      </section>

      <section className={style.section_paragraph}>
        <Paragraph className={style.paragrahp}>
          <strong>Tema de discusión: </strong>{" "}
          <textarea disabled>{data.subject_discussion}</textarea>
        </Paragraph>
      </section>
      {/* 
      <section className={style.section_btn}>
        <Button
          className="fcdt_btn_without_background_primary"
          onClick={handleCloseModal}
        >
          Atrás
        </Button>
        <Button onClick={handleCloseModal}>Continuar</Button>
      </section> */}
    </div>
  );

  return (
    <FCDTModal
      open={open}
      children={children}
      handleClose={handleCloseModal}
      width="47%"
      heigth="90%"
    />
  );
};

export default ModalViewDocument;
