import FCDTModal from "components/modal-notification/fcdt-modal";
import style from "./style/modal-dates.module.scss";
import Button from "components/buttons/fcdt-button";
import { ModalDatesNotificationProps } from "./model";
import { IoHelpCircleSharp } from "react-icons/io5";
import Title from "components/fcdt-title/fcdt-title";

const ModalDates = (props: ModalDatesNotificationProps) => {
  const { handleCloseModal, register, handleSubmit, onSubmit } = props;

  const actualDate = new Date();

  const children = (
    <form className={style.process_container} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.content}>
        <h1>Fechas importantes de la tarea</h1>

        <div className={style.content_left}>
          <div>
            <label htmlFor="date_create">
              <Title fontSize={16}>Fecha optimista</Title>
              <span>
                <IoHelpCircleSharp
                  size={25}
                  className={style.icon_admiration}
                  title="Es la fecha más cercana para entregar la tarea"
                />
              </span>
            </label>
            <input
              type="date"
              placeholder="Fecha de creación  Día / Mes / Año"
              min={actualDate.toISOString().split("T")[0]}
              required
              {...register("date_optimistic")}
            />
          </div>

          <div>
            <label htmlFor="date_create">
              <Title fontSize={16}>Fecha probable</Title>
              <span>
                <IoHelpCircleSharp
                  size={25}
                  className={style.icon_admiration}
                  title="Es la fecha más racional para entregar la tarea"
                />
              </span>
            </label>
            <input
              type="date"
              placeholder="Fecha de creación  Día / Mes / Año"
              min={actualDate.toISOString().split("T")[0]}
              required
              {...register("date_likely")}
            />
          </div>
        </div>
      </div>
      <div className={style.content_buttons}>
        <Button
          className="btn_cancel"
          children="Cancelar"
          width="40"
          type="button"
          onClick={handleCloseModal}
        />
        <Button children="Guardar" width="40" type="submit" />
      </div>
    </form>
  );

  return (
    <FCDTModal open={true} children={children} handleClose={handleCloseModal} />
  );
};

export default ModalDates;
