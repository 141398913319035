import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import { displayLoader, removeProcess } from "./loading";
import * as Api from "pages/knowledge/api/api";
import {
  MeetingKnowledge,
  ReportKnowledge,
  ResponseInformationKnowledge,
  ResponseKnowledgeSelectors,
  ResultsInformationKnowledge,
  ResultsSelectorsKnowledge,
  TaskKnowledge,
} from "pages/knowledge/model";
import { DataMultiSelect } from "components/fcdt-multiselect/model";
import { dataEventKnowledge } from "pages/knowledge/constants";
import { handleMappinValuesKnowledge } from "pages/knowledge/components/helper/mapping-data-knowledge";
import { setDataSpeechTreeSucess } from "./landingPage";
import { formatDate } from "helpers/formatDate";

type InitialState = {
  isReloadNeeded: boolean;
  processes: DataMultiSelect[];
  goals: DataMultiSelect[];
  collaborators: DataMultiSelect[];
  events: DataMultiSelect[];
  goalsSelected: string[];
  eventsSelected: boolean[];
  collaboratorSelected: string[];
  startDate: string | null;
  endDate: string | null;
  keywords: string | null;
  meetingsInformation: MeetingKnowledge[];
  reportsInformation: ReportKnowledge[];
  tasksInformation: TaskKnowledge[];
  meetingSelected?: MeetingKnowledge;
  taskSelected?: TaskKnowledge;
  reportSelected?: ReportKnowledge;
  showGantt: boolean;
};
const initialState: InitialState = {
  isReloadNeeded: true,
  processes: [],
  goals: [],
  collaborators: [],
  events: [],
  goalsSelected: [],
  eventsSelected: [true, true, true],
  collaboratorSelected: [],
  startDate: null,
  endDate: null,
  keywords: null,
  meetingsInformation: [],
  reportsInformation: [],
  tasksInformation: [],
  meetingSelected: undefined,
  taskSelected: undefined,
  reportSelected: undefined,
  showGantt: false,
};
export const slice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setDependenceKnowInit: (state) => {
      state.isReloadNeeded = true;
    },
    setDependenceKnowSucess: (
      state,
      action: PayloadAction<ResultsSelectorsKnowledge | undefined>
    ) => {
      if (action.payload) {
        const addOnePosition: DataMultiSelect = {
          uid: "-1",
          label: "Seleccionar todos",
        };

        if (action.payload.processes) {
          state.processes = [];
          state.processes = action.payload.processes.map((item) => {
            return { uid: item.id_process, label: item.process_name };
          });
          state.processes.unshift(addOnePosition);
        }
        if (action.payload.processes_goals) {
          state.goals = [];

          state.goals = action.payload.processes_goals.map((item) => {
            return { uid: item.id_process_goal, label: item.goal_title };
          });
          state.goals.unshift(addOnePosition);
        }

        state.collaborators = action.payload.goal_collaborators.map((item) => {
          return {
            uid: item.id_collaborator,
            label: item.name_in_meeting_platform,
          };
        });
        state.collaborators.unshift(addOnePosition);
      }
      state.events = [
        { uid: "-1", label: "Seleccionar todos" },
        { uid: "1", label: "Reuniones" },
        { uid: "2", label: "Tareas" },
        { uid: "3", label: "Informes" },
      ];
      state.isReloadNeeded = false;
    },
    setDependenceKnowError: (state) => {
      state.isReloadNeeded = false;
    },
    dataGoalsSelected: (state, action: PayloadAction<string[]>) => {
      state.goalsSelected = action.payload;
    },
    dataCollaboratorSelected: (state, action: PayloadAction<string[]>) => {
      state.collaboratorSelected = action.payload;
    },
    setDataEventSelected: (state, action: PayloadAction<DataMultiSelect[]>) => {
      if (action.payload.length === 0) {
        state.eventsSelected = state.eventsSelected.map(() => false);
        return;
      }

      state.eventsSelected[0] = action.payload.find((item) => item.uid === "1")
        ? true
        : false;

      state.eventsSelected[1] = action.payload.find((item) => item.uid === "2")
        ? true
        : false;

      state.eventsSelected[2] = action.payload.find((item) => item.uid === "3")
        ? true
        : false;
    },
    setDatesNavbar: (
      state,
      action: PayloadAction<{ date: string; service: number }>
    ) => {
      if (action.payload.service === 1) {
        state.startDate = action.payload.date;
      } else {
        state.endDate = action.payload.date;
      }
    },
    setKeywords: (state, action: PayloadAction<string>) => {
      state.keywords = action.payload;
    },
    setDataInformationKnowledge: (
      state,
      action: PayloadAction<ResultsInformationKnowledge | undefined>
    ) => {
      state.meetingSelected = undefined;
      state.taskSelected = undefined;
      state.reportSelected = undefined;
      if (!action.payload) return;
      state.meetingsInformation = action.payload.meetings.map((item) => {
        return { ...item, isSelected: false };
      });
      state.reportsInformation = action.payload.reports.map((item) => {
        return { ...item, isSelected: false };
      });
      state.tasksInformation = action.payload.tasks.map((item) => {
        return { ...item, isSelected: false };
      });
    },
    setSelectedInformationMeeting: (
      state,
      action: PayloadAction<MeetingKnowledge>
    ) => {
      state.meetingsInformation = state.meetingsInformation.map((item) =>
        item.id_meeting === action.payload.id_meeting
          ? { ...item, isSelected: !item.isSelected }
          : { ...item, isSelected: false }
      );
      state.meetingSelected = action.payload;
      state.taskSelected = undefined;
      state.reportSelected = undefined;
    },
    setSelectedInformationTask: (
      state,
      action: PayloadAction<TaskKnowledge>
    ) => {
      state.tasksInformation = state.tasksInformation.map((item) =>
        item.id_task === action.payload.id_task
          ? { ...item, isSelected: !item.isSelected }
          : { ...item, isSelected: false }
      );
      state.taskSelected = action.payload;
      state.meetingSelected = undefined;
      state.reportSelected = undefined;
    },
    setSelectedInformationReports: (
      state,
      action: PayloadAction<ReportKnowledge>
    ) => {
      state.reportsInformation = state.reportsInformation.map((item) =>
        item.id_report === action.payload.id_report
          ? { ...item, isSelected: !item.isSelected }
          : { ...item, isSelected: false }
      );
      state.reportSelected = action.payload;
      state.meetingSelected = undefined;
      state.taskSelected = undefined;
    },
    setShowGantt: (state, action: PayloadAction<boolean>) => {
      state.showGantt = action.payload;
    },
    resetFilterKnowledge: (state) => {
      state.goalsSelected = [];
      state.eventsSelected = [true, true, true];
      state.collaboratorSelected = [];
      state.startDate = null;
      state.endDate = null;
      state.keywords = null;
      state.meetingsInformation = [];
      state.reportsInformation = [];
      state.tasksInformation = [];
      state.meetingSelected = undefined;
      state.taskSelected = undefined;
      state.reportSelected = undefined;
      state.isReloadNeeded = true;
    },
  },
});
export const {
  dataGoalsSelected,
  setDataEventSelected,
  dataCollaboratorSelected,
  setDatesNavbar,
  setKeywords,
  setSelectedInformationMeeting,
  setSelectedInformationTask,
  setSelectedInformationReports,
  setShowGantt,
  resetFilterKnowledge,
} = slice.actions;

export default slice.reducer;

export const getDependencesKnowledge =
  (): AppThunk => async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token, collaborator } = getstate().auth.dataUser!;
      const { id_company } = collaborator;

      dispatch(slice.actions.setDependenceKnowInit());
      const response: ResponseKnowledgeSelectors =
        await Api.getDependencesKnowledge(id_company, null, null, token);
      dispatch(
        slice.actions.setDependenceKnowSucess(
          response.ok ? response.data.results : undefined
        )
      );
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      dispatch(slice.actions.setDependenceKnowError());
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getDependencesKnowledgeFilter =
  (procesess: string[] | null, goals: string[] | null): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;

      const response: ResponseKnowledgeSelectors =
        await Api.getDependencesKnowledge(null, procesess, goals, token);
      dispatch(
        slice.actions.setDependenceKnowSucess(
          response.ok ? response.data.results : undefined
        )
      );
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getInformationKnowledge =
  (searchTerm?: string): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token, collaborator } = getstate().auth.dataUser!;
      const { id_company } = collaborator;
      const {
        goals,
        goalsSelected,
        collaborators,
        collaboratorSelected,
        startDate,
        endDate,
        keywords,
        eventsSelected,
      } = getstate().knowledge;

      const response: ResponseInformationKnowledge =
        await Api.getInformationKnowledge(
          id_company,
          goalsSelected.length > 0
            ? goalsSelected
            : handleMappinValuesKnowledge(goals),
          collaboratorSelected.length > 0
            ? collaboratorSelected
            : handleMappinValuesKnowledge(collaborators),
          startDate ? formatDate(startDate) + "T00:00:00" : null,
          endDate ? formatDate(endDate) + "T00:00:00" : null,
          keywords,
          searchTerm ? searchTerm : null,
          eventsSelected,
          token
        );
      dispatch(
        slice.actions.setDataInformationKnowledge(
          response.ok ? response.data.results : undefined
        )
      );
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getSpeechTreeByIdMeeting =
  (idMeeting: string): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;

      const response = await Api.getSpeechTreeByIdMeeting(idMeeting, token);
      dispatch(
        setDataSpeechTreeSucess(response.ok ? response.data.values : [])
      );
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
