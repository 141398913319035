import Button from "components/buttons/fcdt-button";
import ImageNewPassword from "assets/img-new-password.webp";
import style from "../style/newpassword.module.scss";
import { useForm } from "react-hook-form";
import { RestorePassword } from "../model";
import { useDispatch } from "react-redux";
import { Errors } from "components/errors/errors";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { updatePassword } from "store/slices/login";
import { ROUTES } from "routes/constants";

export const NewPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RestorePassword>();
  const dispatch = useDispatch<any>();
  const params = useParams();
  const token = params.token!;
  const navigate = useNavigate();
  const onSubmit = async (data: RestorePassword) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Las contraseñas no coinciden", {duration: 7000, position:"top-center"});
      return;
    }
    const response = await dispatch(updatePassword(token, data.password));

    if (!response.ok) {
      toast.error(response.msg, {duration: 7000, position:"top-center"});
      return;
    }
    toast.success(response.msg, {duration: 7000, position:"top-center"});
    navigate(ROUTES.LOGIN);
  };
  return (
    <div className={style.new_password_container}>
      <h2>Crear nueva contraseña</h2>
      <img src={ImageNewPassword} alt="Crear nueva contraseña" />
      <label>Su contraseña debe ser diferente a la anterior</label>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          className="fcdt-input"
          type="password"
          placeholder="Contraseña"
          maxLength={18}
          minLength={8}
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%*.])[A-Za-z\d@#$%*.]{8,30}$"
          title="La contraseña debe tener entre 8 y 18 caracteres, debe contener minúscula(s), mayúscula(s), número(s) y un caracter especial como @#$%*."
          {...register("password", { required: true })}
        />
        <Errors name={errors.password} />
        <input
          className="fcdt-input"
          type="password"
          placeholder="Repetir contraseña"
          maxLength={18}
          minLength={8}
          {...register("confirmPassword", { required: true })}
        />
        <Errors name={errors.confirmPassword} />
        <Button children={"Guardar"} type="submit" />
      </form>
    </div>
  );
};
