import { GoPlusCircle } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import style from "../style/newplan.module.scss";
import {
  createPlan,
  getFunctionalities,
  getPlans,
  setFormPlan,
  setFunctionalities,
  setOpenNewFunction,
  setOpenNewPlan,
  setSelectedPlan,
  setSuccesPlan,
  setUpdatePlan,
  updatePlan,
} from "store/slices/superAdmin";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/buttons/fcdt-button";
import { FormNewPlan } from "../model";
import { useForm } from "react-hook-form";
import { Errors } from "components/errors/errors";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { MoneyField } from "../../moneyField/money-field";

export const NewPlan = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    control,
  } = useForm<FormNewPlan>();
  const dispatch = useDispatch<any>();
  const {
    isOpenNewPlan,
    formPlan,
    isUpdatePlan,
    functionalitiesPlan,
    functionalities,
    selectedPlan,
  } = useSelector((state: any) => state.superadmin);  
  const handleCloseDialog = () => {
    dispatch(setOpenNewPlan(false));
    dispatch(setUpdatePlan(false));
    dispatch(setSelectedPlan({}));
  };
  const handleOpenDialog = () => {
    dispatch(setFormPlan(watch()));
    dispatch(setOpenNewFunction(true));
    dispatch(setOpenNewPlan(!isOpenNewPlan));
  };

  useEffect(() => {
    const featchData = async () => {
      const response: any = await dispatch(getFunctionalities());
      if (response?.length > 0) {
        dispatch(setFunctionalities(response));
      }
    };

    featchData();
    if (formPlan && isOpenNewPlan) {
      // reset(formPlan);
      
    }

    if (isUpdatePlan) {
      // reset(selectedPlan?.plan);
    }

  }, [formPlan, isOpenNewPlan]);

  const onSubmit = async (data: FormNewPlan) => {
    const response: any = isUpdatePlan
      ? await dispatch(updatePlan(data))
      : await dispatch(createPlan(data));

    if (response?.ok) {
      toast.success(response.msg, { duration: 7000, position: "top-center" });
      if (isUpdatePlan) {
        dispatch(setUpdatePlan(false));
        dispatch(setOpenNewPlan(false));
        dispatch(setSelectedPlan({}));
      } else {
        dispatch(setSuccesPlan(true));
      }
      dispatch(getPlans());
      // reset();
    }
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.new_plan_container}>
        <div className={style.header}>
          {/* <button type="button" onClick={() => handleReset()}><IoRefreshSharp size={35} color="#2f0084" /> Actualizar funciones </button> */}
          <h2>Nuevo plan</h2>
          <button type="button" onClick={handleCloseDialog}>
            {" "}
            <IoMdClose size={35} color="#2f0084" />{" "}
          </button>
        </div>
        <div className={style.content}>
          <div className={style.content_left}>
            <input
              type="text"
              placeholder="Nombre del plan"
              {...register("plan_name", {
                required: true,
                minLength: 4,
                maxLength: 30,
                pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9.,:()-áéíóúÁÉÍÓÚ\s]+$/,
              })}
            />
            <Errors name={errors.plan_name} />
            <textarea
              className="fcdt-input"
              placeholder="Descripción"
              {...register("plan_description", {
                required: true,
                minLength: 6,
                maxLength: 150,
                pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9.,:()-áéíóúÁÉÍÓÚ\s]+$/,
              })}
            />
            <Errors name={errors.plan_description} />
            <MoneyField
              name={"plan_price"}
              placeholder="Valor"
              control={control}
              rules={{ required: true }}
              error={errors.plan_price}
            />
          </div>
          <div className={style.content_right}>
            <button
              type="button"
              onClick={() => {
                handleOpenDialog();
              }}
            >
              {" "}
              <GoPlusCircle size={35} color="#00de97" /> Añadir funcionalidades
            </button>
            <ul className={style.content_checkbox}>
              {functionalities?.length > 0 ? (
                functionalities?.map((item: any, index: any) => (
                  <li key={index}>
                    <input
                      id={item.id}
                      type="checkbox"
                      value={item.id}
                      defaultChecked={
                        selectedPlan?.functionalitiesPlan?.some(
                          (selected: any) =>
                            selected?.id_functionality === item.id_functionality
                        )
                      }
                      {...register(`functionalitiesPlan`, { required: true })}

                    />
                    
                    
                  <div style={{ width: "80%" }}>
                      {item.functionality_name}
                    </div>
                          <input
                            type="number"
                            style={{ width: "25%", textAlign: "center" }}
                            placeholder="#"
                            {...register(`credit_quantities`)}
                            value={item.credit_quantities}
                          />
                  </li>
                ))
              ) : (
                <li>No hay funcionalidades creadas</li>
              )}
            </ul>
            <Errors name={errors.functionalitiesPlan} />
          </div>
        </div>
        <div className={style.content_buttons}>
          <Button
            className="btn_cancel"
            children="Cancelar"
            width="40"
            onClick={handleCloseDialog}
          />
          <Button children="Guardar" width="40" onClick={() => handleSubmit} />
        </div>
      </div>
    </form>
  );
};
